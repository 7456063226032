
import {createAction, props} from '@ngrx/store';
import {AppGroup as Entity} from './app-group.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";

export const clone = createAction(
    '[AppGroup] Clone AppGroup',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
    '[AppGroup] Clone AppGroup Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
    '[AppGroup] Clone AppGroup Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
    '[AppGroup] Load AppGroups',
    props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
    '[AppGroup] Load AppGroups Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
    '[AppGroup] Load AppGroups Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
    '[AppGroup] Load Single AppGroup',
    props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
    '[AppGroup] Load Single AppGroup Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
    '[AppGroup] Load Single AppGroup Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
    '[AppGroup] Load Paginated AppGroups',
    props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
    '[AppGroup] Load Paginated AppGroups Success',
    props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
    '[AppGroup] Load Paginated AppGroups Failed',
    props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
    '[AppGroup] Create AppGroup',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
    '[AppGroup] Create AppGroup Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
    '[AppGroup] Create AppGroup Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
    '[AppGroup] Create Many AppGroups',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
    '[AppGroup] Create Many AppGroups Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
    '[AppGroup] Create Many AppGroups Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
    '[AppGroup] Update AppGroup',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
    '[AppGroup] Update AppGroup Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
    '[AppGroup] Update AppGroup Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
    '[AppGroup] Update Many AppGroups',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
    '[AppGroup] Update Many AppGroups Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
    '[AppGroup] Update Many AppGroups Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
    '[AppGroup] Remove AppGroup',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
    '[AppGroup] Remove AppGroup Success',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeFailure = createAction(
    '[AppGroup] Remove AppGroup Failure',
    props<{ error: Error, correlationId?: string }>()
);


export const setCurrentEntity = createAction(
    '[AppGroup] Set Current Entity',
    props<{ entity: Entity }>()
);

export const setCurrentEntityById = createAction(
    '[AppGroup] Set Current Entity By Id',
    props<{ entityId: number }>()
);

export const AppGroupActions = {
    loadPage,
    loadPageSuccess,
    loadPageFailure,
    load,
    loadSuccess,
    loadFailure,
    loadSingle,
    loadSingleSuccess,
    loadSingleFailure,
    create,
    createSuccess,
    createFailure,
    createMany,
    createManySuccess,
    createManyFailure,
    update,
    updateSuccess,
    updateFailure,
    updateMany,
    updateManySuccess,
    updateManyFailure,
    remove,
    removeSuccess,
    removeFailure,

    clone,
    cloneSuccess,
    cloneFailure,
    setCurrentEntity,
    setCurrentEntityById
};
