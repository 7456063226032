import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';


@Injectable({
    providedIn: 'root',
})
class I18n {
    // "Default if no localstorage exists"
    locale = 'sv';

    async setLocale() {
        const userLocale = localStorage.getItem('locale');
        if (userLocale) {
            this.locale = userLocale;
        }

        // no 'en-US' .mjs file, 'en' is for "all" english languages.
        const mjsPath = this.locale === 'en-US' ? 'en' : this.locale;
        // Use web pack magic string to only include required locale data
        const localeModule = await import(
            /* webpackInclude: /(en|sv|fi|de|fr|nl|no)\.mjs$/ */
            `/node_modules/@angular/common/locales/${mjsPath}.mjs`
            );
        registerLocaleData(localeModule.default);

        // Dont need to translate if en is lang
        if (mjsPath != 'en') {
          const localeTranslationsModule = await import(`/src/app/locale/json/${this.locale}.json`);
          loadTranslations(localeTranslationsModule.default);
        }
    }
}

// Load locale data at app start-up
function setLocale() {
    return {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18n) => () => i18n.setLocale(),
        deps: [I18n],
        multi: true,
    };
}

// Set the runtime locale for the app
function setLocaleId() {
    return {
        provide: LOCALE_ID,
        useFactory: (i18n: I18n) => i18n.locale,
        deps: [I18n],
    };
}

export const I18nModule = {
    setLocale: setLocale,
    setLocaleId: setLocaleId,
};
