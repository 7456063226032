import {createAction, props} from '@ngrx/store';
import {Resource as Entity} from './resource.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[Resource] Clone Resource',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[Resource] Clone Resource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[Resource] Clone Resource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[Resource] Load Resources',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[Resource] Load Resources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[Resource] Load Resources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[Resource] Load Single Resource if necessary',
  props<{ entityId: string, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[Resource] Load Single Resource',
  props<{ entityId: string, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[Resource] Load Single Resource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[Resource] Load Single Resource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[Resource] Load Paginated Resources',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[Resource] Load Paginated Resources Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[Resource] Load Paginated Resources Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[Resource] Create Resource',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[Resource] Create Resource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[Resource] Create Resource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[Resource] Create Many Resources',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[Resource] Create Many Resources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[Resource] Create Many Resources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[Resource] Update Resource',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[Resource] Update Resource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[Resource] Update Resource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[Resource] Update Many Resources',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[Resource] Update Many Resources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[Resource] Update Many Resources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[Resource] Remove Resource',
  props<{ entityId: string, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[Resource] Remove Resource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[Resource] Remove Resource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[Resource] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[Resource] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[Resource] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[Resource] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[Resource] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const ResourceActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary
};
