import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginModule} from "./features/login/login.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppStoreModule} from "./store/app-store.module";
import {TokenInterceptor} from "./auth/interceptors/token-handler.interceptor";
import {ErrorHandlingInterceptor} from "./auth/interceptors/error-handling.interceptor";
import {provideCloudflareLoader} from "@angular/common";
import {environment} from "../environments/environment";
import {I18nModule} from './core/i18n/i18n.module';

import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    // Log the error to the console.
    console.error(error);
 }
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    AppStoreModule,
    LoginModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
      CustomErrorHandler,
    provideCloudflareLoader(environment.cloudFlare),
    I18nModule.setLocale(), I18nModule.setLocaleId(),
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
