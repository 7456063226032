import {createAction, props} from '@ngrx/store';
import {CheckoutRow as Entity} from './checkout-row.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";

export const clone = createAction(
    '[CheckoutRow] Clone CheckoutRow',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
    '[CheckoutRow] Clone CheckoutRow Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
    '[CheckoutRow] Clone CheckoutRow Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
    '[CheckoutRow] Load CheckoutRows',
    props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
    '[CheckoutRow] Load CheckoutRows Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
    '[CheckoutRow] Load CheckoutRows Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
    '[CheckoutRow] Load Single CheckoutRow',
    props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
    '[CheckoutRow] Load Single CheckoutRow Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
    '[CheckoutRow] Load Single CheckoutRow Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
    '[CheckoutRow] Load Paginated CheckoutRows',
    props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
    '[CheckoutRow] Load Paginated CheckoutRows Success',
    props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
    '[CheckoutRow] Load Paginated CheckoutRows Failed',
    props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
    '[CheckoutRow] Create CheckoutRow',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
    '[CheckoutRow] Create CheckoutRow Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
    '[CheckoutRow] Create CheckoutRow Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
    '[CheckoutRow] Create Many CheckoutRows',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
    '[CheckoutRow] Create Many CheckoutRows Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
    '[CheckoutRow] Create Many CheckoutRows Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
    '[CheckoutRow] Update CheckoutRow',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
    '[CheckoutRow] Update CheckoutRow Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
    '[CheckoutRow] Update CheckoutRow Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
    '[CheckoutRow] Update Many CheckoutRows',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
    '[CheckoutRow] Update Many CheckoutRows Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
    '[CheckoutRow] Update Many CheckoutRows Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
    '[CheckoutRow] Remove CheckoutRow',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeByTemplateId = createAction(
    '[CheckoutRow] Remove CheckoutRows by templateId',
    props<{ templateId: number,checkoutId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
    '[CheckoutRow] Remove CheckoutRow Success',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeManySuccess = createAction(
    '[CheckoutRow] Remove many CheckoutRows Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const removeFailure = createAction(
    '[CheckoutRow] Remove CheckoutRow Failure',
    props<{ error: Error, correlationId?: string }>()
);


export const setCurrentEntity = createAction(
    '[CheckoutRow] Set Current Entity',
    props<{ entity: Entity }>()
);

export const CheckoutRowActions = {
    loadPage,
    loadPageSuccess,
    loadPageFailure,
    load,
    loadSuccess,
    loadFailure,
    loadSingle,
    loadSingleSuccess,
    loadSingleFailure,
    create,
    createSuccess,
    createFailure,
    createMany,
    createManySuccess,
    createManyFailure,
    update,
    updateSuccess,
    updateFailure,
    updateMany,
    updateManySuccess,
    updateManyFailure,

    remove,
    removeSuccess,
    removeFailure,
    removeManySuccess,
    removeByTemplateId,

    clone,
    cloneSuccess,
    cloneFailure,
    setCurrentEntity,

};
