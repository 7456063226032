import {createAction, props} from '@ngrx/store';
import {Checkout, Checkout as Entity, OrderResult} from './checkout.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";
import { Criteria } from "src/app/store/app-store.types";

export const clone = createAction(
  '[Checkout] Clone Checkout',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[Checkout] Clone Checkout Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[Checkout] Clone Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[Checkout] Load Checkouts',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[Checkout] Load Checkouts Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[Checkout] Load Checkouts Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[Checkout] Load Single Checkout',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[Checkout] Load Single Checkout Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[Checkout] Load Single Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[Checkout] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadPage = createAction(
  '[Checkout] Load Paginated Checkouts',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[Checkout] Load Paginated Checkouts Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[Checkout] Load Paginated Checkouts Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[Checkout] Create Checkout',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[Checkout] Create Checkout Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[Checkout] Create Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[Checkout] Create Many Checkouts',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[Checkout] Create Many Checkouts Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[Checkout] Create Many Checkouts Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[Checkout] Update Checkout',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[Checkout] Update Checkout Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[Checkout] Update Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[Checkout] Update Many Checkouts',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[Checkout] Update Many Checkouts Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[Checkout] Update Many Checkouts Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[Checkout] Remove Checkout',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[Checkout] Remove Checkout Success',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[Checkout] Remove Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const setCurrentEntity = createAction(
  '[Checkout] Set Current Entity',
  props<{ entity: Entity }>()
);

export const deselectEntities = createAction(
  '[Checkout] Deselect Current Entities'
);

export const deselectCurrent = createAction(
    '[Checkout] Deselect Current Entity'
);

export const setCurrentEntityId = createAction(
  '[Checkout] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setActiveCheckout = createAction(
  '[Checkout] Set Active Checkout',
  props<{ entity: Entity }>()
);

export const getActiveCheckout = createAction(
  '[Checkout] Get Active Checkout',
  props<{ correlationId?: string }>()
);

export const getActiveCheckoutSuccess = createAction(
  '[Checkout] Get Active Checkout Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const getActiveCheckoutFailure = createAction(
  '[Checkout] Get Active Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const exportProductionFiles = createAction('[Checkout] Export ProductionFiles Checkout', props<{
  checkoutId: number,
  correlationId: string;
}>());
export const exportProductionFilesSuccess = createAction('[Checkout] Export ProductionFiles Checkout Success', props<{
  entity: Checkout,
  correlationId: string;
}>());
export const exportProductionFilesFailure = createAction('[Checkout] Export ProductionFiles Checkout Failure', props<{
  error: Error,
  correlationId: string;
}>());

export const sendProductionFiles = createAction('[Checkout] Send ProductionFiles Checkout', props<{
  checkoutRowId: number,
  correlationId: string;
}>());
export const sendProductionFilesSuccess = createAction('[Checkout] Send ProductionFiles Checkout Success', props<{
  entity: Checkout,
  correlationId: string;
}>());
export const sendProductionFilesFailure = createAction('[Checkout] Send ProductionFiles Checkout Failure', props<{
  error: Error,
  correlationId: string;
}>());

export const loadProductionOrdersPage = createAction('[Checkout] Select ProductionOrders Checkout', props<{
  page: IPage,
  criteria?: any,
  correlationId: string;
}>());



export const createOrder = createAction(
  '[Checkout] Create Order Checkout',
  props<{ checkout: Checkout, correlationId?: string }>()
);

export const createOrderSuccess = createAction(
  '[Checkout] Create Order Checkout Success',
  props<{ orderResult: OrderResult, correlationId?: string }>()
);

export const createOrderFailure = createAction(
  '[Checkout] Create Order Checkout Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const startCheckoutStatusPolling = createAction(
  '[Checkout] Start Polling Checkout Status',
  props<{ checkoutIds: number[], correlationId?: string }>);

export const stopCheckoutStatusPolling = createAction(
  '[Checkout] Stop Polling Checkout Status');

export const checkoutStatusPollingError = createAction(
  '[Checkout] Polling Checkout Status Error',
  props<{ error: Error, correlationId?: string }>());

export const updateCheckoutStatuses = createAction(
  '[Checkout] Update Checkout Statuses',
  props<{ checkouts: Entity[], correlationId?: string }>());

export const setPaginationSettings = createAction(
  '[Checkout] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[Checkout] Set Sort Settings',
  props <{ tableSort: ITableSort }>()
);

export const CheckoutActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,
  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  exportProductionFiles,
  exportProductionFilesSuccess,
  exportProductionFilesFailure,
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  setActiveCheckout,
  getActiveCheckout,
  getActiveCheckoutSuccess,
  getActiveCheckoutFailure,
  sendProductionFiles,
  sendProductionFilesSuccess,
  sendProductionFilesFailure,
  startCheckoutStatusPolling,
  stopCheckoutStatusPolling,
  checkoutStatusPollingError,

  setSortSettings,
  setPaginationSettings,

  deselectEntities,
  deselectCurrent,
  setCurrentEntityId,

  loadSingleIfNecessary,
  loadProductionOrdersPage
};
