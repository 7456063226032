import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Product} from "./product.model";

@Injectable({providedIn: 'root'})
export class ProductService {
    constructor(private http: HttpClient) {
    }

    getTotalCount(): Observable<number> {
      return this.http.get<number>(
        `${environment.rootUrl}/product/getall`
      )
    }

    clone(id: number, cloneProducts: boolean): Observable<Product> {
        return this.http.get<Product>(
            `${environment.rootUrl}/product/clone/${id}/${cloneProducts}`
        );
    }

  getTotalProductColours(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.rootUrl}/product/get-product-colours`
    );  }
}
