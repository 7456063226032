import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppUser as Entity} from './app-user.model';
import * as EntityActions from './app-user.actions';
import {AppStoreEntityService} from "src/app/store/app-store-entity.service";

import {AppUserService} from "./app-user.service";
import {TokenInfo} from "src/app/auth/models/auth.model";
import {IResultWithPageInfo} from "../app-store.interfaces";
import {select, Store} from "@ngrx/store";
import {AppUserSelectors} from "./app-user.selectors";
import {AppState} from "../app-store.state";

@Injectable()
export class AppUserEffects {
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.signIn),
      switchMap(({username, password, correlationId}) =>
        this.appUserService.signIn(username, password).pipe(
          map((tokenInfo: TokenInfo) =>
            EntityActions.signInSuccess({tokenInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.signInFailure({error, correlationId}))
          )
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.signOut),
      switchMap(({correlationId}) =>
        this.appUserService.signOut().pipe(
          map(() =>
            EntityActions.signOutSuccess({ correlationId})

          ),
          catchError((error) =>
            of(EntityActions.signInFailure({error, correlationId}))
          )
        )
      )
    )
  );

  signInToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.signInToken),
      switchMap(({token, userId, correlationId}) =>
        this.appUserService.signInToken(token, userId).pipe(
          map((tokenInfo: TokenInfo) =>
            EntityActions.signInTokenSuccess({tokenInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.signInTokenFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadPage),
      switchMap(({page, criteria, correlationId}) =>
        this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
          map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
            EntityActions.loadPageSuccess({resultWithPageInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadPageFailure({error, correlationId}))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.load),
      switchMap(({correlationId}) =>
        this.appStoreEntityService.load<Entity>(this.EntityInfo).pipe(
          map((entities: Entity[]) =>
            EntityActions.loadSuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingle),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.loadSingle<Entity>(this.EntityInfo, entityId).pipe(
          map((entity: Entity) =>
            EntityActions.loadSingleSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadSingleFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingleIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingleIfNecessary),
      mergeMap((action) =>
        this.store.pipe(
          select(AppUserSelectors.selectById(action.entityId)),
          take(1),
          switchMap((entity) => {
            if (entity)
              return of(EntityActions.loadSingleSuccess({entity, correlationId: action.correlationId}));

            const {entityId, correlationId} = action;
            return this.appStoreEntityService
              .loadSingle<Entity>(this.EntityInfo, entityId)
              .pipe(
                map((entity: Entity) =>
                  EntityActions.loadSingleSuccess({entity, correlationId})
                ),
                catchError((error) =>
                  of(EntityActions.loadSingleFailure({error, correlationId}))
                )
              );
          })
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.create),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.create<Entity>(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  createMany = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.createMany<Entity[]>(this.EntityInfo, entities).pipe(
          map((result: Entity[]) =>
            EntityActions.createManySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.update(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.updateMany(this.EntityInfo, entities).pipe(
          map((results: Entity[]) =>
            EntityActions.updateManySuccess({entities: results, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.remove),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.remove(this.EntityInfo, entityId).pipe(
          map(() =>
            EntityActions.removeSuccess({entityId, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

  EntityInfo = {modelName: "app-user"};

  constructor(
    private store: Store<AppState>,
    private appUserService: AppUserService,
    private actions$: Actions,
    private appStoreEntityService: AppStoreEntityService
  ) {
  }
}
