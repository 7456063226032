import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Article} from "./article.model";

@Injectable({providedIn: 'root'})
export class ArticleService {
    constructor(private http: HttpClient) {
    }

    clone(id: number, cloneProducts: boolean): Observable<Article> {
        return this.http.get<Article>(
            `${environment.rootUrl}/article/clone/${id}/${cloneProducts}`
        );
    }
}
