import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ProductBase} from "./product-base.model";
import {IEntityInfo} from "../app-store.interfaces";

@Injectable({providedIn: 'root'})
export class ProductBaseService {
    constructor(private http: HttpClient) {
    }

    getTotalCount(): Observable<number> {
      return this.http.get<number>(
        `${environment.rootUrl}/product/getall`
      )
    }

    clone(id: number, cloneProducts: boolean): Observable<ProductBase> {
        return this.http.get<ProductBase>(
            `${environment.rootUrl}/product/clone/${id}/${cloneProducts}`
        );
    }

    loadSingleProductBase<T>(entityInfo: IEntityInfo, id: number | string, productCategoryId: number): Observable<T> {
      return this.http.get<T>(
        `${
          environment.rootUrl
        }/${entityInfo.modelName}/${id}/${productCategoryId}`
      );
    }
}
