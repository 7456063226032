import {createAction, props} from '@ngrx/store';
import {AppRole as Entity} from './app-role.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
    '[AppRole] Clone AppRole',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
    '[AppRole] Clone AppRole Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
    '[AppRole] Clone AppRole Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
    '[AppRole] Load AppRoles',
    props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
    '[AppRole] Load AppRoles Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
    '[AppRole] Load AppRoles Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
    '[AppRole] Load Single AppRole',
    props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
    '[AppRole] Load Single AppRole Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
    '[AppRole] Load Single AppRole Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
    '[AppRole] Load Paginated AppRoles',
    props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
    '[AppRole] Load Paginated AppRoles Success',
    props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
    '[AppRole] Load Paginated AppRoles Failed',
    props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
    '[AppRole] Create AppRole',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
    '[AppRole] Create AppRole Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
    '[AppRole] Create AppRole Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
    '[AppRole] Create Many AppRoles',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
    '[AppRole] Create Many AppRoles Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
    '[AppRole] Create Many AppRoles Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
    '[AppRole] Update AppRole',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
    '[AppRole] Update AppRole Success',
    props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
    '[AppRole] Update AppRole Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
    '[AppRole] Update Many AppRoles',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
    '[AppRole] Update Many AppRoles Success',
    props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
    '[AppRole] Update Many AppRoles Failure',
    props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
    '[AppRole] Remove AppRole',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
    '[AppRole] Remove AppRole Success',
    props<{ entityId: number, correlationId?: string }>()
);

export const removeFailure = createAction(
    '[AppRole] Remove AppRole Failure',
    props<{ error: Error, correlationId?: string }>()
);


export const setCurrentEntity = createAction(
    '[AppRole] Set Current Entity',
    props<{ entity: Entity }>()
);

export const setCurrentEntityById = createAction(
    '[AppRole] Set Current Entity By Id',
    props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[AppRole] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[AppRole] Set Sort Settings',
  props <{ tableSort: ITableSort }>()
);
export const AppRoleActions = {
    loadPage,
    loadPageSuccess,
    loadPageFailure,
    load,
    loadSuccess,
    loadFailure,
    loadSingle,
    loadSingleSuccess,
    loadSingleFailure,
    create,
    createSuccess,
    createFailure,
    createMany,
    createManySuccess,
    createManyFailure,
    update,
    updateSuccess,
    updateFailure,
    updateMany,
    updateManySuccess,
    updateManyFailure,
    remove,
    removeSuccess,
    removeFailure,

    clone,
    cloneSuccess,
    cloneFailure,
    setCurrentEntity,
    setCurrentEntityById,

    setPaginationSettings,
    setSortSettings
};
