import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Checkout, OrderResult} from "./checkout.model";
import {environment} from "src/environments/environment";
import {map} from "rxjs/operators";
import {PaginatedResult} from "../app-store-entity.service";
import {IPage, IResultWithPageInfo} from "../app-store.interfaces";
import {Criteria} from "../app-store.types";

const httpOptions = {
    headers: new HttpHeaders({
        ContentType: 'application/json',
        Accept: 'application/json',
    }),
};

@Injectable({providedIn: 'root'})
export class CheckoutService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getActiveCheckout(): Observable<Checkout> {
        return this.http.get<Checkout>(
            `${environment.rootUrl}/checkout/get-active`,
            httpOptions,
        );
    }

    getStatusForCheckouts(checkoutIds: number[]): Observable<Checkout[]> {
        return this.http.post<Checkout[]>(
            `${environment.rootUrl}/checkout/get-checkout-status-for-client`,
            checkoutIds,
            httpOptions,
        );
    }


  createOrder(checkout: Checkout): Observable<OrderResult> {
    return this.http.post<OrderResult>(
      `${environment.rootUrl}/checkout/create-market-order`,
      checkout
    );
  }


}
