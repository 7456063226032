import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {AppUser as Entity} from "./app-user.model";


import {TokenInfo} from "src/app/auth/models/auth.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface AppUserState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntity: Entity | undefined,
  history: { correlationId: string, payload: Entity | Entity[] | number | TokenInfo | IResultWithPageInfo<Entity> }[];
  currentUser: Entity | undefined;
  token: string | undefined;
  claims: number[] | undefined;
}

export const appUserEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: AppUserState = appUserEntityAdapter.getInitialState({
  entities: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
  currentSort: undefined,
  currentEntity: undefined,
  history: [],
  token: undefined,
  currentUser: undefined,
  claims: undefined
});
