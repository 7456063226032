import {createAction, props} from '@ngrx/store';
import {TemplateTag as Entity} from './template-tag.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[TemplateTag] Clone Template Tag',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[TemplateTag] Clone Template Tag Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[TemplateTag] Clone Template Tag Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[TemplateTag] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[TemplateTag] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[TemplateTag] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[TemplateTag] Load Single Template Tag if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[TemplateTag] Load Single Template Tag',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[TemplateTag] Load Single Template Tag Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[TemplateTag] Load Single Template Tag Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[TemplateTag] Load Paginated Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[TemplateTag] Load Paginated Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[TemplateTag] Load Paginated Templates Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[TemplateTag] Create Template Tag',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[TemplateTag] Create Template Tag Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[TemplateTag] Create Template Tag Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[TemplateTag] Create Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[TemplateTag] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[TemplateTag] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[TemplateTag] Update Template Tag',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[TemplateTag] Update Template Tag Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[TemplateTag] Update Template Tag Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[TemplateTag] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[TemplateTag] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[TemplateTag] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[TemplateTag] Remove Template Tag',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[TemplateTag] Remove Template Tag Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[TemplateTag] Remove Template Tag Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[TemplateTag] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[TemplateTag] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[TemplateTag] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[TemplateTag] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[TemplateTag] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const TemplateTagActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary
};
