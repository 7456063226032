import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {interval, of} from 'rxjs';
import {Checkout as Entity} from './checkout.model';
import * as EntityActions from './checkout.actions';
import {AppStoreEntityService} from "src/app/store/app-store-entity.service";

import {CheckoutService} from "./checkout.service";
import {CheckoutSelectors as Selectors} from "./checkout.selectors";
import {select, Store} from "@ngrx/store";
import {AppState} from "src/app/store/app-store.state";
import {IResultWithPageInfo} from "../app-store.interfaces";
import {getActiveCheckoutSuccess} from "./checkout.actions";

@Injectable()
export class CheckoutEffects {

  getActiveCheckout$ = createEffect(() =>
      this.actions$.pipe(
        ofType(EntityActions.getActiveCheckout),
        switchMap(({correlationId}) =>
            this.checkoutService.getActiveCheckout().pipe(
                map((checkout) => {
                    return EntityActions.getActiveCheckoutSuccess({entity: checkout, correlationId})
                }),
                catchError(error => of(EntityActions.getActiveCheckoutFailure({error, correlationId})),)
            )
        )
      )
  );

    createOrderWithResult = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.createOrder),
            switchMap(({checkout, correlationId}) =>
                this.checkoutService.createOrder(checkout).pipe(
                    map((orderResult) => {
                        return EntityActions.createOrderSuccess({
                            orderResult,
                            correlationId}
                        )
                    }),
                    catchError(error => of(EntityActions.createOrderFailure({error, correlationId})),)
                )
            )
        )
    );

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadPage),
      switchMap(({page, criteria, correlationId}) =>
        this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
          map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
            EntityActions.loadPageSuccess({resultWithPageInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadPageFailure({error, correlationId}))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.load),
      switchMap(({correlationId, criteria}) =>
        this.appStoreEntityService.load<Entity>(this.EntityInfo, criteria).pipe(
          map((entities: Entity[]) =>
            EntityActions.loadSuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingle),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.loadSingle<Entity>(this.EntityInfo, entityId).pipe(
          map((entity: Entity) =>
            EntityActions.loadSingleSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadSingleFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingleIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingleIfNecessary),
      mergeMap((action) =>
        this.store.pipe(
          select(Selectors.selectById(action.entityId)),
          take(1),
          switchMap((entity) => {
            if (entity)
              return of(EntityActions.loadSingleSuccess({entity, correlationId: action.correlationId}));

            const {entityId, correlationId} = action;
            return this.appStoreEntityService
              .loadSingle<Entity>(this.EntityInfo, entityId)
              .pipe(
                map((entity: Entity) =>
                  EntityActions.loadSingleSuccess({entity, correlationId})
                ),
                catchError((error) =>
                  of(EntityActions.loadSingleFailure({error, correlationId}))
                )
              );
          })
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.create),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.create<Entity>(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  createMany = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.createMany<Entity[]>(this.EntityInfo, entities).pipe(
          map((result: Entity[]) =>
            EntityActions.createManySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.update(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.updateMany(this.EntityInfo, entities).pipe(
          map((results: Entity[]) =>
            EntityActions.updateManySuccess({entities: results, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.remove),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.remove(this.EntityInfo, entityId).pipe(
          map(() =>
            EntityActions.removeSuccess({entityId, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

  EntityInfo = {modelName: "checkout"};

  constructor(
    private checkoutService: CheckoutService,
    private actions$: Actions,
    private appStoreEntityService: AppStoreEntityService,
    private store: Store<AppState>
  ) {
  }
}
