import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ProductBaseCategory} from "./product-base-category.model";

@Injectable({providedIn: 'root'})
export class ProductBaseCategoryService {
  constructor(private http: HttpClient) {
  }

  getTotalCount(): Observable<number> {
    return this.http.get<number>(
      `${environment.rootUrl}/product-base-category/getall`
    )
  }

  clone(id: number, cloneProducts: boolean): Observable<ProductBaseCategory> {
    return this.http.get<ProductBaseCategory>(
      `${environment.rootUrl}/product-base-category/clone/${id}/${cloneProducts}`
    );
  }
}
