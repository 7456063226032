import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CheckoutRow as Entity} from './checkout-row.model';
import * as EntityActions from './checkout-row.actions';
import {AppStoreEntityService} from "src/app/store/app-store-entity.service";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";
import {removeByTemplateId} from "./checkout-row.actions";
import {CheckoutRowService} from "./checkout-row.service";


@Injectable()
export class CheckoutRowEffects {
  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadPage),
      switchMap(({page, criteria, correlationId}) =>
        this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
          map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
            EntityActions.loadPageSuccess({resultWithPageInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadPageFailure({error, correlationId}))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.load),
      switchMap(({correlationId, criteria}) =>
        this.appStoreEntityService.load<Entity>(this.EntityInfo, criteria).pipe(
          map((entities: Entity[]) =>
            EntityActions.loadSuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingle),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.loadSingle<Entity>(this.EntityInfo, entityId).pipe(
          map((entity: Entity) =>
            EntityActions.loadSingleSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadSingleFailure({error, correlationId}))
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.create),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.create<Entity>(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  createMany = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.createMany<Entity[]>(this.EntityInfo, entities).pipe(
          map((result: Entity[]) =>
            EntityActions.createManySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.update(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.updateMany(this.EntityInfo, entities).pipe(
          map((results: Entity[]) =>
            EntityActions.updateManySuccess({entities: results, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.remove),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.remove(this.EntityInfo, entityId).pipe(
          map(() =>
            EntityActions.removeSuccess({entityId, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

  removeByTemplateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.removeByTemplateId),
      switchMap(({templateId, checkoutId, correlationId}) =>
        this.checkoutRowService.removeByTemplateId(templateId,checkoutId).pipe(
          map((entities) =>
            EntityActions.removeManySuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

  EntityInfo = {modelName: "checkout-row"};

  constructor(
    private actions$: Actions,
    private appStoreEntityService: AppStoreEntityService,
    private checkoutRowService: CheckoutRowService,
  ) {
  }
}
