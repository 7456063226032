import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {mergeMap, of, switchMap} from "rxjs";
import {catchError, map, take} from "rxjs/operators";
import { ResourceItemActions as EntityActions } from './resource-item.actions'
import { ResourceItemSelectors as Selectors} from "./resource-item.selectors";
import { ResourceItem as Entity } from './resource-item.model';
import {select, Store} from "@ngrx/store";
import {AppState} from "../app-store.state";
import {AppStoreEntityService} from "../app-store-entity.service";
import {ResourceItemService} from "./resource-item.service";
import {IResultWithPageInfo} from "../app-store.interfaces";

@Injectable()
export class ResourceItemEffects {

  clone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.clone),
      switchMap(({entity, correlationId}) =>
        this.resourceItemService.clone(entity.id).pipe(
          map((entity: Entity) =>
            EntityActions.cloneSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.cloneFailure({error, correlationId}))
          )
        )
      )
    ));

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadPage),
      switchMap(({page, criteria, correlationId}) =>
        this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
          map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
            EntityActions.loadPageSuccess({resultWithPageInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadPageFailure({error, correlationId}))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.load),
      switchMap(({criteria, correlationId}) =>
        this.appStoreEntityService.load<Entity>(this.EntityInfo, criteria).pipe(
          map((entities: Entity[]) =>
            EntityActions.loadSuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingle),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.loadSingle<Entity>(this.EntityInfo, entityId).pipe(
          map((entity: Entity) =>
            EntityActions.loadSingleSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadSingleFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingleIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingleIfNecessary),
      mergeMap((action) =>
        this.store.pipe(
          select(Selectors.selectById(action.entityId)),
          take(1),
          switchMap((entity) => {
            if (entity)
              return of(EntityActions.loadSingleSuccess({entity, correlationId: action.correlationId}));

            const {entityId, correlationId} = action;
            return this.appStoreEntityService
              .loadSingle<Entity>(this.EntityInfo, entityId)
              .pipe(
                map((entity: Entity) =>
                  EntityActions.loadSingleSuccess({entity, correlationId})
                ),
                catchError((error) =>
                  of(EntityActions.loadSingleFailure({error, correlationId}))
                )
              );
          })
        )
      )
    )
  );

  makeTransparent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.makeTransparent),
      switchMap(({entity, correlationId}) =>
        this.resourceItemService.makeTransparent(entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.create),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.create<Entity>(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  createMany = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.createMany<Entity[]>(this.EntityInfo, entities).pipe(
          map((result: Entity[]) =>
            EntityActions.createManySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.update(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.updateMany(this.EntityInfo, entities).pipe(
          map((results: Entity[]) =>
            EntityActions.updateManySuccess({entities: results, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.remove),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.remove<Entity>(this.EntityInfo, entityId).pipe(
          map((entity) =>
            EntityActions.removeSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

  EntityInfo = {modelName: "resource-item"};

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private appStoreEntityService: AppStoreEntityService,
    private resourceItemService: ResourceItemService
  ) {
  }
}
