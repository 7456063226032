import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Template as Entity} from "./template.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface TemplateState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  entitiesTemporary: Entity[];
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentPageEntities: number[] | undefined,
  currentSort: ITableSort | undefined;
  currentEntityId: number | undefined,
  currentEntityIds: number[];

  currentRelatedEntities: Entity[] | undefined,
  currentRelatedEntitiesCurrentPage: IPageInfo;
  currentRelatedEntitiesCurrentSort: ITableSort | undefined;

  history: { correlationId: string, payload: Entity | Entity[] | number | number[] | IResultWithPageInfo<Entity> }[];
  currentProductCategoryId: number | undefined;
}

export const templateEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: TemplateState = templateEntityAdapter.getInitialState({
  entities: [],
  entitiesTemporary: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentPageEntities: undefined,
  currentSort: undefined,
  currentEntityId: undefined,
  currentEntityIds: [],
  history: [],
  currentProductCategoryId: undefined,

  currentRelatedEntities: undefined,
  currentRelatedEntitiesCurrentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentRelatedEntitiesCurrentSort: undefined,
});
