import {createSelector} from '@ngrx/store';
import {productEntityAdapter as EntityAdapter, ProductState as EntityState} from "./product.state";
import {Product as Entity} from './product.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.product;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);
export const currentEntityIds = createSelector(selectState, (state: EntityState) => state.currentEntityIds);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const currentEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityIds
    ? state.currentEntityIds.map(id => state.entities[id]).filter(y => y !== undefined)
    : undefined;
});

export const isCurrentEntity = (entityId: number) =>
  createSelector(selectState, (state: EntityState) => state.currentEntityId === entityId);

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);
export const totalCount = createSelector(selectState, (state: EntityState) => state.totalCount);
export const currentColours = createSelector(selectState, (state: EntityState) => state.currentColours);
export const ProductSelectors = {
  selectAll,
  selectById,
  isLoading,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  currentEntityId,
  totalPageable,
  currentPage,
  currentSort,
  totalCount,
  currentEntityIds,
  currentEntities,
  currentColours,
  isCurrentEntity
};
