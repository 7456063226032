import {createAction, props} from '@ngrx/store';
import {ProductFace as Entity} from './product-face.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";

export const clone = createAction(
  '[ProductFace] Clone Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ProductFace] Clone Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ProductFace] Clone Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[ProductFace] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ProductFace] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ProductFace] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[ProductFace] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[ProductFace] Load Single Template',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[ProductFace] Load Single Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ProductFace] Load Single Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ProductFace] Load Paginated Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[ProductFace] Load Paginated Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const createWithResource = createAction(
  '[UserResource] Create ProductFace with resource',
  props<{ entity: Entity, file: File, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ProductFace] Load Paginated Templates Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[ProductFace] Create Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ProductFace] Create Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ProductFace] Create Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createInStore = createAction(
  '[ProductFace] Create In Store Entity',
  props<{ entity: Entity }>()
);

export const updateInStore = createAction(
  '[ProductFace] Update In Store Entity',
  props<{ entity: Entity }>()
);

export const removeInStore = createAction(
  '[ProductFace] Remove In Store Entity',
  props<{ entity: Entity }>()
);

export const clearStore = createAction(
  '[ProductFace] Clear Store Entity'
);

export const saveStoreChanges = createAction(
  '[ProductFace] Save store changes',
  props<{ correlationId?: string }>()
);

export const saveStoreChangesSuccess = createAction(
  '[ProductFace] Save store changes success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const saveStoreChangesFailure = createAction(
  '[ProductFace] Save store changes failure',
  props<{  error: Error, correlationId?: string }>()
);


export const createMany = createAction(
  '[ProductFace] Create Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ProductFace] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ProductFace] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[ProductFace] Update Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ProductFace] Update Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ProductFace] Update Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ProductFace] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ProductFace] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ProductFace] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[ProductFace] Remove Template',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ProductFace] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ProductFace] Remove Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ProductFace] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[ProductFace] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ProductFace] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setActiveInStore = createAction(
  '[ProductFace] Set Active Entity Id in Store',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[ProductFace] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ProductFace] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const ProductFaceActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,
  createWithResource,

  createInStore,
  updateInStore,
  removeInStore,
  setActiveInStore,
  saveStoreChanges,
  saveStoreChangesSuccess,
  saveStoreChangesFailure,
  clearStore
};
