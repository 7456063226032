import {createReducer, on} from "@ngrx/store";
import {appClaimEntityAdapter as EntityAdapter, initialState} from "./app-claim.state";
import {AppClaimActions as Actions} from "./app-claim.actions";

export const appClaimReducer = createReducer(
    initialState,

    on(Actions.load, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId
    })),

    on(Actions.loadSuccess, (state, {entities, correlationId}) => {
            if (!correlationId)
                return EntityAdapter.setAll(entities, {
                    ...state,
                    isLoading: false,
                    error: undefined,
                });

            return EntityAdapter.setAll(entities, {
                ...state,
                isLoading: false,
                error: undefined,
                correlationId,
                history: [...state.history, {correlationId, payload: entities}]
            });
        }
    ),

    on(Actions.loadFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.create, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.createSuccess, (state, {entity, correlationId}) => {
            if (!correlationId)
                return EntityAdapter.addOne(entity, {
                    ...state,
                    isLoading: false,
                    error: undefined,
                });

            return EntityAdapter.addOne(entity, {
                ...state,
                isLoading: false,
                error: undefined,
                correlationId,
                history: [...state.history, {correlationId, payload: entity}]
            });
        }
    ),

    on(Actions.createFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.createMany, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.createManySuccess, (state, {entities, correlationId}) => {
        if (!correlationId)
            return EntityAdapter.addMany(entities, {
                ...state,
                isLoading: false,
                error: undefined,
            });

        return EntityAdapter.addMany(entities, {
            ...state,
            isLoading: false,
            error: undefined,
            correlationId,
            history: [...state.history, {correlationId, payload: entities}],
        });
    }),

    on(Actions.createManyFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),


    on(Actions.update, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.updateSuccess, (state, {entity, correlationId}) => {
            if (!correlationId)
                return EntityAdapter.updateOne(
                    {id: entity.id, changes: entity},
                    {
                        ...state,
                        isLoading: false,
                        error: undefined,
                    });

            return EntityAdapter.updateOne(
                {id: entity.id, changes: entity},
                {
                    ...state,
                    isLoading: false,
                    error: undefined,
                    correlationId,
                    history: [...state.history, {correlationId, payload: entity}]
                });
        }
    ),

    on(Actions.updateFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.updateMany, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.updateManySuccess, (state, {entities, correlationId}) => {
        if (!correlationId)
            return EntityAdapter.updateMany(
                entities.map(entity => ({id: entity.id, changes: entity})),
                {
                    ...state,
                    isLoading: false,
                    error: undefined,
                });

        return EntityAdapter.updateMany(
            entities.map(entity => ({id: entity.id, changes: entity})),
            {
                ...state,
                isLoading: false,
                error: undefined,
                correlationId,
                history: [...state.history, {correlationId, payload: entities}],
            });
    }),

    on(Actions.updateManyFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.remove, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.removeSuccess, (state, {entityId, correlationId}) => {
        if (!correlationId)
            return EntityAdapter.removeOne(entityId, {
                ...state,
                isLoading: false,
                error: undefined,
            });

        return EntityAdapter.removeOne(entityId, {
            ...state,
            isLoading: false,
            error: undefined,
            correlationId,
            history: [...state.history, {correlationId, payload: entityId}]
        });
    }),

    on(Actions.removeFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.loadPage, (state, {correlationId}) => ({
        ...state,
        isLoading: true,
        correlationId,
    })),

    on(Actions.loadPageSuccess, (state, {resultWithPageInfo, correlationId}) => {
        if (!correlationId)
            return EntityAdapter.setAll(resultWithPageInfo.entities, {
                ...state,
                isLoading: false,
                error: undefined,
                currentPage: resultWithPageInfo.pageInfo,
            });

        return EntityAdapter.setAll(resultWithPageInfo.entities, {
            ...state,
            isLoading: false,
            error: undefined,
            correlationId,
            currentPage: resultWithPageInfo.pageInfo,
            history: [...state.history, {correlationId, payload: resultWithPageInfo}],
        });
    }),

    on(Actions.loadPageFailure, (state, {error, correlationId}) => ({
        ...state,
        isLoading: false,
        error,
        correlationId,
    })),

    on(Actions.setCurrentEntity, (state, {entity}) => ({
        ...state,
        currentEntity: entity,
    })),
);
