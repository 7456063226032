import {createSelector} from '@ngrx/store';
import {appUserEntityAdapter as EntityAdapter, AppUserState as EntityState} from "./app-user.state";
import {AppUser as Entity} from './app-user.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.appUser;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error !== undefined);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntity = createSelector(selectState, (state: EntityState) => state.currentEntity);
export const currentUser = createSelector(selectState, (state: EntityState) => state.currentUser);
export const currentRoles = createSelector(selectState, (state: EntityState) => state.currentUser?.roles);
export const currentRoleNames = createSelector(selectState, (state: EntityState) => state.currentUser?.roles.map(x => x.name));
export const hasRole = (roleName: string) => createSelector(selectState,
  (state: EntityState) => state.currentUser?.roles?.some(x => x.name === roleName)
);

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);
export const AppUserSelectors = {
  selectAll,
  selectById,
  isLoading,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  totalPageable,
  currentUser,
  currentPage,
  currentSort,
  currentRoles,
  hasRole,
  currentRoleNames
};
