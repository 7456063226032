<div
  class="h-screen font-sans flex items-center justify-center"
  style="background-image: url('https://images.unsplash.com/photo-1502472584811-0a2f2feb8968?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218MHx8bmF0dXJlfHx8fHx8MTY4Mjc2MjUzNQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600'); background-size: cover; background-position: center;"
>
  <div class="w-full max-w-md bg-white bg-opacity-90 rounded-lg shadow-md p-8">
    <mat-progress-bar mode="indeterminate" class="absolute top-0 left-0 right-0" *ngIf="(appUsers.isLoading$ | async)"></mat-progress-bar>
    <form (submit)="signIn()" [formGroup]="loginForm" class="relative">
      <div class="text-center mb-8">
        <p class="text-3xl font-bold text-gray-800" i18n>LOGIN</p>
      </div>
      <div class="mb-4">
        <label i18n class="block text-sm text-gray-700 mb-2" for="email">E-mail</label>
        <input i18n-placeholder
          aria-label="email"
          class="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
          formControlName="email"
          id="email"
          matInput
          placeholder="E-mail"
          required
          type="email"
        />
      </div>
      <div class="mb-6">
        <label i18n class="block text-sm text-gray-700 mb-2">Password</label>
        <input i18n-placeholder
          aria-label="password"
          class="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
          formControlName="password"
          id="password"
          matInput
          placeholder="Password"
          required
          type="password"
        />
      </div>
      <div class="mt-4 items-center flex justify-between">
        <div *ngIf="appUsers.hasError$ | async" class="font-bold text-red-500" id="error">
         <span i18n>Invalid login attempt</span>
        </div>
        <button i18n
          class="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          color="primary"
          mat-flat-button
          id="login-btn"
          type="submit">
          Login
        </button>
      </div>
    </form>
    <div class="text-center w-full text-xs mt-6">
      <span>Version 1</span>
      <br/>
    </div>
  </div>
</div>
