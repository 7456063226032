// hydration.reducer.ts
import {Action, ActionReducer, INIT} from "@ngrx/store";
import {AppUserActions} from "./app-user/app-user.actions";
import {AppState} from "./app-store.state";

export const keepStateMetaReducer = (
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
    return (state, action) => {
        const nextState = reducer(state, action);

        if (action.type === INIT) {
          const currentUser = localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user') ?? '') : undefined;
          const claims = localStorage.getItem('claims') ? JSON.parse(localStorage.getItem('claims') ?? '') as number[] : undefined;

            return {
                ...nextState,
                appUser: {
                    ...nextState.appUser,
                    currentUser: currentUser,
                    token: localStorage.getItem('token') ?? undefined,
                    claims: claims,
                }
            };
        }

        return nextState;
    };
};

export function signOutMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>): ActionReducer<S, A> {
    return function (state: S | undefined, action: A): S {
        if (action.type === AppUserActions.signOutSuccess.type) {
            localStorage.clear();
            return reducer(undefined, action);
        }
        return state === undefined ? reducer(undefined, action) : reducer(state, action);
    };
}
