import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ProductFace} from "./product-face.model";

@Injectable({providedIn: 'root'})
export class ProductFaceService {
    constructor(private http: HttpClient) {
    }

    createWithFile(productFace: ProductFace, formFile: File): Observable<ProductFace> {
      const formData = new FormData()
      formData.append('formFile', formFile);

      for (let key in productFace) {
        if(key === 'productPlacements')
          continue;

        formData.append(`ProductFace.${key}`, (productFace as any)[key]);
      }

      productFace.productPlacements.forEach((placement, index) => {
        for (let key in placement) {
          formData.append(`ProductFace.ProductPlacements[${index}].${key}`, (placement as any)[key]);
        }
      });

      return this.http.post<ProductFace>(`${environment.rootUrl}/product-face/create-with-file/`, formData);
    }

    clone(id: number, cloneProducts: boolean): Observable<ProductFace> {
        return this.http.get<ProductFace>(
            `${environment.rootUrl}/product-face/clone/${id}/${cloneProducts}`
        );
    }
}
