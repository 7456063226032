import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Claim } from "src/app/store/claim/claim.model";
import {environment} from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class AppClaimService {
    constructor(private http: HttpClient) {
    }

    getClaimsForRole(roleId: number): Observable<Claim[]> {
        return this.http
            .get<Claim[]>(`${environment.rootUrl}/app-claim/get-claims-for-role/${roleId}`)
    }

    setClaimsForRole(roleId: number, claims: Claim[]): Observable<Claim[]> {
        return this.http
            .post<Claim[]>(`${environment.rootUrl}/app-claim/set-claims-for-role/`, {roleId, claims})
    }
}
