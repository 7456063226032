import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppClaimEffects } from "./app-claim/app-claim.effects";
import { AppClaimFacade } from "./app-claim/app-claim.facade";
import { appClaimReducer } from "./app-claim/app-claim.reducers";
import { AppGroupEffects } from "./app-group/app-group.effects";
import { AppGroupFacade } from "./app-group/app-group.facade";
import { appGroupReducer } from "./app-group/app-group.reducers";
import { AppRoleEffects } from "./app-role/app-role.effects";
import { AppRoleFacade } from "./app-role/app-role.facade";
import { appRoleReducer } from "./app-role/app-role.reducers";
import { AppUserEffects } from "./app-user/app-user.effects";
import { appUserReducer } from "./app-user/app-user.reducers";
import { ProductEffects } from "./product/product.effects";
import { ProductFacade } from "./product/product.facade";
import { TemplateEffects } from "./template/template.effects";
import { TemplateFacade } from "./template/template.facade";
import { templateReducer } from "./template/template.reducers";
import {AppUserFacade} from "./app-user/app-user.facade";
import {AppStoreEntityService} from "./app-store-entity.service";
import { keepStateMetaReducer, signOutMetaReducer} from "./app-store-meta.reducer";
import {userResourceReducer} from "./user-resource/user-resource.reducers";
import {resourceReducer} from "./resource/resource.reducers";
import {templateGroupReducer} from "./template-group/template-group.reducers";
import {templatePreviewReducer} from "./template-preview/template-preview.reducers";
import {templateGraphicReducer} from "./template-graphic/template-graphic.reducers";
import {productCategoryReducer} from "./product-category/product-category.reducers";
import {productReducer} from "./product/product.reducers";
import {articleReducer} from "./article/article.reducers";
import {productFaceReducer} from "./product-face/product-face.reducers";
import {ArticleEffects} from "./article/article.effects";
import {ProductFaceEffects} from "./product-face/product-face.effects";
import {ProductCategoryEffects} from "./product-category/product-category.effects";
import {TemplateGraphicEffects} from "./template-graphic/template-graphic.effects";
import {TemplateGroupEffects} from "./template-group/template-group.effects";
import {TemplatePreviewEffects} from "./template-preview/template-preview.effects";
import {ResourceEffects} from "./resource/resource.effects";
import {UserResourceEffects} from "./user-resource/user-resource.effects";
import {TemplatePreviewFacade} from "./template-preview/template-preview.facade";
import {TemplateGroupFacade} from "./template-group/template-group.facade";
import {ProductFaceFacade} from "./product-face/product-face.facade";
import {ArticleFacade} from "./article/article.facade";
import {ResourceFacade} from "./resource/resource.facade";
import {UserResourceFacade} from "./user-resource/user-resource.facade";
import {productPlacementReducer} from "./product-placement/product-placement.reducers";
import {ProductPlacementEffects} from "./product-placement/product-placement.effects";
import {resourceItemReducer} from "./resource-item/resource-item.reducers";
import {ResourceItemEffects} from "./resource-item/resource-item.effects";
import {ResourceItemFacade} from "./resource-item/resource-item.facade";
import {productBaseReducer} from "./product-base/product-base.reducers";
import {ProductBaseEffects} from "./product-base/product-base.effects";
import {ProductBaseFacade} from "./product-base/product-base.facade";
import {productBaseCategoryReducer} from "./product-base-category/product-base-category.reducers";
import {ProductBaseCategoryEffects} from "./product-base-category/product-base-category.effects";
import {ProductBaseCategoryFacade} from "./product-base-category/product-base-category.facade";
import {templateTagReducer} from "./template-tag/template-tag.reducers";
import {TemplateTagEffects} from "./template-tag/template-tag.effects";
import {TemplateTagFacade} from "./template-tag/template-tag.facade";
import {checkoutReducer} from "./checkout/checkout.reducers";
import {CheckoutEffects} from "./checkout/checkout.effects";
import {CheckoutFacade} from "./checkout/checkout.facade";
import {checkoutRowReducer} from "./checkout-row/checkout-row.reducers";
import {CheckoutRowEffects} from "./checkout-row/checkout-row.effects";
import {CheckoutRowFacade} from "./checkout-row/checkout-row.facade";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot({
            appClaim: appClaimReducer,
            appGroup: appGroupReducer,
            appRole: appRoleReducer,
            appUser: appUserReducer,
            article: articleReducer,
            product: productReducer,
            checkout: checkoutReducer,
            checkoutRow: checkoutRowReducer,
            productBase: productBaseReducer,
            productBaseCategory: productBaseCategoryReducer,
            productPlacement: productPlacementReducer,
            productFace: productFaceReducer,
            productCategory: productCategoryReducer,
            template: templateReducer,
            templateGroup: templateGroupReducer,
            templateTag: templateTagReducer,
            templatePreview: templatePreviewReducer,
            templateGraphic: templateGraphicReducer,
            resource: resourceReducer,
            resourceItem: resourceItemReducer,
            userResource: userResourceReducer,
          },
          {
            metaReducers: [
                keepStateMetaReducer,
                signOutMetaReducer,
            ],
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
          }),

        EffectsModule.forRoot([
          AppClaimEffects,
          AppGroupEffects,
          AppRoleEffects,
          AppUserEffects,
          ArticleEffects,
          ProductEffects,
          CheckoutEffects,
          CheckoutRowEffects,
          ProductBaseEffects,
          ProductBaseCategoryEffects,
          ProductPlacementEffects,
          ProductFaceEffects,
          ProductCategoryEffects,
          TemplateEffects,
          TemplateGraphicEffects,
          TemplateGroupEffects,
          TemplateTagEffects,
          TemplatePreviewEffects,
          ResourceEffects,
          ResourceItemEffects,
          UserResourceEffects
        ]),
    ],
    providers: [
      AppStoreEntityService,

      AppClaimEffects,
      AppClaimFacade,

      AppGroupEffects,
      AppGroupFacade,

      AppRoleEffects,
      AppRoleFacade,

      AppUserEffects,
      AppUserFacade,

      ArticleFacade,
      ArticleEffects,

      CheckoutFacade,
      CheckoutEffects,

      CheckoutRowFacade,
      CheckoutRowEffects,

      ProductEffects,
      ProductFacade,

      ProductBaseEffects,
      ProductBaseFacade,

      ProductBaseCategoryEffects,
      ProductBaseCategoryFacade,

      ProductFaceFacade,
      ProductFaceEffects,

      ProductCategoryEffects,
      ProductFaceFacade,

      TemplateEffects,
      TemplateFacade,

      TemplatePreviewEffects,
      TemplatePreviewFacade,

      TemplateGroupEffects,
      TemplateGroupFacade,

      TemplateTagEffects,
      TemplateTagFacade,

      ResourceEffects,
      ResourceFacade,

      ResourceItemEffects,
      ResourceItemFacade,

      UserResourceEffects,
      UserResourceFacade,
    ],
    exports: [
        StoreModule,
        EffectsModule
    ],
})
export class AppStoreModule {
}
