import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {TokenInfo} from "src/app/auth/models/auth.model";

@Injectable({providedIn: 'root'})
export class AppUserService {
    constructor(private http: HttpClient) {
    }

    signIn(username: string, password: string): Observable<TokenInfo> {
        const httpParams = new HttpParams()
            .append('username', username)
            .append('password', password);
        return this.http
            .get<TokenInfo>(`${environment.rootUrl}/app-user/sign-in`, {params: httpParams})
    }

    signInToken(token: string, userId: number): Observable<TokenInfo> {
        const httpParams = new HttpParams()
            .append('token', token)
            .append('userId', userId);

        return this.http
            .get<TokenInfo>(
                `${environment.rootUrl}/app-user/sign-in-token`,
                {params: httpParams},
            )
    }

    signOut(): Observable<boolean> {
        return this.http
            .get<boolean>(`${environment.rootUrl}/app-user/sign-out`)
    }

    // loadLimited(): Observable<AppUser[]> {
    //   return this.http
    //     .get<PaginatedResult>(`${environment.rootUrl}/app-user/load-limited`)
    //     .pipe(
    //       map(({ items }) => (items))
    //     );
    // }
}
