import {createAction, props} from '@ngrx/store';
import {ProductBase as Entity} from './product-base.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clearPage = createAction(
  '[ProductBase] Clear Page'
);

export const clearProductBasePageForEditor = createAction(
  '[ProductBase] Clear Page for Editor'
);

export const loadAndSelect = createAction(
  '[ProductBase] Load And Select Product',
  props<{ productId: number, correlationId?: string }>()
);

export const loadAndSelectIfNecessary = createAction(
  '[ProductBase] Load And Select Product',
  props<{ productBaseId: number, productCategoryId: number | undefined, correlationId?: string }>()
);

export const loadAndSelectSuccess = createAction(
  '[ProductBase] Load And Select ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadAndSelectFailure = createAction(
  '[ProductBase] Load And Select ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const clone = createAction(
  '[ProductBase] Clone Product',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ProductBase] Clone ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ProductBase] Clone ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[ProductBase] Load Products',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ProductBase] Load Products Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ProductBase] Load Products Failure',
  props<{ error: Error, correlationId?: string }>()
);

// export const loadSingleIfNecessary = createAction(
//   '[ProductBase] Load Single Products if necessary',
//   props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
// );
//
// export const loadSingle = createAction(
//   '[ProductBase] Load Single Product',
//   props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
// );

export const loadSingleSuccess = createAction(
  '[ProductBase] Load Single ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ProductBase] Load Single ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ProductBase] Load Paginated Products',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadProductBasePageForEditor = createAction(
  '[ProductBase] Load Paginated Products For Editor',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadProductBasePageForEditorSuccess = createAction(
  '[ProductBase] Load Paginated Products for editor Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[ProductBase] Load Paginated Products Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ProductBase] Load Paginated Products Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[ProductBase] Create Products',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ProductBase] Create ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ProductBase] Create ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[ProductBase] Create Many Products',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ProductBase] Create Many Products Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ProductBase] Create Many Products Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[ProductBase] Update Product',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ProductBase] Update ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ProductBase] Update ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ProductBase] Update Many Products',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ProductBase] Update Many Products Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ProductBase] Update Many Products Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[ProductBase] Remove Product',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ProductBase] Remove ProductBase Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ProductBase] Remove ProductBase Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ProductBase] Deselect Current Entity'
);

export const deselectEntity = createAction(
  '[ProductBase] Deselect Selected Entity',
  props<{ entityId: number }>()
);


export const setCurrentEntity = createAction(
  '[ProductBase] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ProductBase] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const selectEntity = createAction(
  '[ProductBase] Select Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[ProductBase] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setPaginationSettingsForEditor = createAction(
  '[ProductBase] Set Pagination Settings for editor',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ProductBase] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const getTotalProductCount = createAction(
  '[ProductBase] Get All Products'
)

export const updateTotalProductCount = createAction(
  '[ProductBase] Update Total ProductBase Count',
  props<{ count: number }>()
);

export const getTotalProductCountFailed = createAction(
  '[ProductBase] Get Total ProductBase Count Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const ProductBaseActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  // loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,
  deselectEntity,
  selectEntity,

  setPaginationSettingsForEditor,
  setPaginationSettings,
  setSortSettings,

  // loadSingleIfNecessary,
  loadAndSelect,
  loadAndSelectIfNecessary,
  loadAndSelectSuccess,
  loadAndSelectFailure,

  getTotalProductCount,
  updateTotalProductCount,
  getTotalProductCountFailed,

  loadProductBasePageForEditor,
  loadProductBasePageForEditorSuccess,
  clearProductBasePageForEditor,
  clearPage
};
