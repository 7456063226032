import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {map, mergeMap, catchError, switchMap, take} from 'rxjs/operators';
import { of } from 'rxjs';
import { ProductCategory as Entity } from './product-category.model';
import {AppStoreEntityService} from "../app-store-entity.service";
import {ProductCategoryActions as EntityActions, selectForClone,} from './product-category.actions'
import {ProductCategorySelectors as Selectors} from "./product-category.selectors";
import {ProductCategoryService} from "./product-category.service";
import {IResultWithPageInfo} from "../app-store.interfaces";
import {select, Store} from "@ngrx/store";
import {AppState} from "../app-store.state";
import {Router} from "@angular/router";
import {log} from "fabric/fabric-impl";

@Injectable()
export class ProductCategoryEffects {

  clone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.clone),
      switchMap(({entity, correlationId}) =>
        this.productCategoryService.clone(entity.id, true).pipe(
          map((entity: Entity) =>
            EntityActions.cloneSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.cloneFailure({error, correlationId}))
          )
        )
      )
    ));

    selectForClone = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.selectForClone),
            switchMap(({page, criteria, correlationId}) =>
                this.productCategoryService.selectForClone(page, criteria).pipe(
                    map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
                        EntityActions.selectForCloneSuccess({resultWithPageInfo, correlationId})
                    ),
                    catchError((error) =>
                        of(EntityActions.selectForCloneFailure({error, correlationId}))
                    )
                )
            )
        )
    );

  getRootCategoryId = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.getRootCategory),
      switchMap(({ productCategoryId, correlationId}) =>
        this.productCategoryService.getRootCategoryId(productCategoryId).pipe(
          map((productCategoryId: number) =>
            EntityActions.getRootCategorySuccess({productCategoryId, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.getRootCategoryFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadPage),
      switchMap(({page, criteria, correlationId}) =>
        this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
          map((resultWithPageInfo: IResultWithPageInfo<Entity>) =>
            EntityActions.loadPageSuccess({resultWithPageInfo, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadPageFailure({error, correlationId}))
          )

        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.load),
      switchMap(({criteria, correlationId}) =>
        this.appStoreEntityService.load<Entity>(this.EntityInfo, criteria).pipe(
          map((entities: Entity[]) =>
            EntityActions.loadSuccess({entities, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingle),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.loadSingle<Entity>(this.EntityInfo, entityId).pipe(
          map((entity: Entity) =>
            EntityActions.loadSingleSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadSingleFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadSingleIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadSingleIfNecessary),
      mergeMap((action) =>
        this.store.pipe(
          select(Selectors.selectById(action.entityId)),
          take(1),
          switchMap((entity) => {
            if (entity)
              return of(EntityActions.loadSingleSuccess({entity, correlationId: action.correlationId}));

            const {entityId, correlationId} = action;
            return this.appStoreEntityService
              .loadSingle<Entity>(this.EntityInfo, entityId)
              .pipe(
                map((entity: Entity) =>
                  EntityActions.loadSingleSuccess({entity, correlationId})
                ),
                catchError((error) =>
                  of(EntityActions.loadSingleFailure({error, correlationId}))
                )
              );
          })
        )
      )
    )
  );

  loadIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadIfNecessary),
      mergeMap((action) =>
        this.store.pipe(
          select(Selectors.isEmpty),
          take(1),
          switchMap((isEmpty) => {
            if (!isEmpty)
              return of();

            const { correlationId, criteria} = action;
            return this.appStoreEntityService
              .load<Entity>(this.EntityInfo, criteria)
              .pipe(
                map((entities: Entity[]) =>
                  EntityActions.loadSuccess({entities, correlationId})
                ),
                catchError((error) =>
                  of(EntityActions.loadFailure({error, correlationId}))
                )
              );
          })
        )
      )
    )
  );

    loadRelatedProductCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.loadRelatedProductCategories),
            switchMap(({page, criteria, correlationId}) =>
                this.appStoreEntityService.loadPage<Entity>(this.EntityInfo, page, criteria).pipe(
                    map((resultWithPageInfo: IResultWithPageInfo<Entity>) => {
                        return EntityActions.loadRelatedProductCategoriesSuccess({resultWithPageInfo, correlationId})
                    }                    ),
                    catchError((error) =>
                        of(EntityActions.loadPageFailure({error, correlationId}))
                    )
                )
            )
        )
    );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.create),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.create<Entity>(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadRelatedHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadRelatedHierarchy),
      switchMap(({productCategoryId, correlationId}) =>
        this.productCategoryService.loadRelatedHierarchy(productCategoryId).pipe(
          map((result: Entity[]) =>
            EntityActions.loadRelatedHierarchySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadRelatedHierarchyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  loadNavigationHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.loadNavigationHierarchy),
      switchMap(({productCategoryId, correlationId}) =>
        this.productCategoryService.loadNavigationHierarchy(productCategoryId).pipe(
          map((result: Entity[]) =>
            EntityActions.loadNavigationHierarchySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.loadNavigationHierarchyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  createWithFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createWithFile),
      switchMap(({entity,file, url, productIds, correlationId}) =>
        this.productCategoryService.createWithFile(entity, file, productIds, url).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

  exportProductCategory = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.exportCategoryWithResult),
      switchMap(({productCategoryId, correlationId}) =>
        this.productCategoryService.exportProductCategoryJson(productCategoryId).pipe(
          map((exportFile) => {
            return EntityActions.exportCategoryWithResultSuccess({ exportFile });
          }),
          catchError((error) => of(EntityActions.exportCategoryWithResultFailure({ error })))
        )
      )
    )
  );

  checkoutCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.checkoutProductCategory),
      switchMap(({entity, correlationId}) => {
          console.log('add', entity, correlationId)
          return this.productCategoryService.checkoutProductCategory(entity).pipe(
            map((orderResult) => {
                console.log('success', orderResult, correlationId)
                return EntityActions.checkoutProductCategorySuccess({entity, orderResult, correlationId})
              }
            ),
            catchError((error) => {
                console.log('error', error, entity, correlationId)
                return of(EntityActions.checkoutProductCategoryFailure({entity, error, correlationId}));
              }
            )
          );
        }
      )
    )
  );

  createMany = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.createMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.createMany<Entity[]>(this.EntityInfo, entities).pipe(
          map((result: Entity[]) =>
            EntityActions.createManySuccess({entities: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      switchMap(({entity, correlationId}) =>
        this.appStoreEntityService.update(this.EntityInfo, entity).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateWithRelatedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateWithData),
      switchMap(({entity, file, correlationId}) =>
        this.productCategoryService.updateWithRelatedData(entity, file).pipe(
          map((result: Entity) =>
            EntityActions.updateSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateFailure({error, correlationId}))
          )
        )
      )
    )
  );

  cloneWithRelatedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.cloneWithData),
      switchMap(({entity,templates,file,correlationId}) =>
        this.productCategoryService.cloneWithRelatedData(entity, templates, file).pipe(
          map((result: Entity) =>
            EntityActions.createSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.createFailure({error, correlationId}))
          )
        )
      )
    )
  );

    cloneManyWithRelatedData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.cloneManyWithData),
            switchMap(({
                    entities,
                    templates,
                    articleExtendedData,
                    file,
                    correlationId
                }) =>
                this.productCategoryService.cloneManyWithRelatedData(entities,templates,articleExtendedData, file).pipe(
                    map((result: Entity) =>
                        EntityActions.cloneSuccess({entity: result, correlationId})
                    ),
                    catchError((error) =>
                        of(EntityActions.cloneFailure({error, correlationId}))
                    )
                )
            )
        )
    );

  cloneSubCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.cloneSubCategories),
      switchMap(({
                   originalProductCategoryId,
                   productCategoryToCloneToIds,
                   entities,
                   templates,
                   correlationId
                 }) =>
        this.productCategoryService.cloneSubCategories(originalProductCategoryId,productCategoryToCloneToIds, entities,templates).pipe(
          map((result: Entity) =>
            EntityActions.cloneSuccess({entity: result, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.cloneFailure({error, correlationId}))
          )
        )
      )
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateMany),
      switchMap(({entities, correlationId}) =>
        this.appStoreEntityService.updateMany(this.EntityInfo, entities).pipe(
          map((results: Entity[]) =>
            EntityActions.updateManySuccess({entities: results, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.updateManyFailure({error, correlationId}))
          )
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.remove),
      switchMap(({entityId, correlationId}) =>
        this.appStoreEntityService.remove<Entity>(this.EntityInfo, entityId).pipe(
          map((entity) =>
            EntityActions.removeSuccess({entity, correlationId})
          ),
          catchError((error) =>
            of(EntityActions.removeFailure({error, correlationId}))
          )
        )
      )
    )
  );

    removeProductsFromCategory = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.removeProductsFromCategory),
            switchMap(({productIds, entityId, correlationId}) =>
                this.productCategoryService.removeProductsFromCategory(productIds, entityId).pipe(
                    map((entity) =>
                        EntityActions.removeProductsFromCategorySuccess({entity, correlationId})
                    ),
                    catchError((error) =>
                        of(EntityActions.removeFailure({error, correlationId}))
                    )
                )
            )
        )
    );

    updateProductList = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityActions.updateProductList),
            switchMap(({productIds, entityId, correlationId}) =>
                this.productCategoryService.updateProductCategoryProductList(productIds, entityId).pipe(
                    map((entity) =>
                        EntityActions.updateSuccess({entity, correlationId})
                    ),
                    catchError((error) =>
                        of(EntityActions.updateFailure({error, correlationId}))
                    )
                )
            )
        )
    );

  getTotalProductCategoryCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.getTotalProductCategoryCount),
      switchMap(() =>
        this.productCategoryService.getTotalCount().pipe(
          map((count) => EntityActions.updateTotalProductCategoryCount({ count })),
          catchError((error) => of(EntityActions.getTotalProductCategoryCountFailed({ error })))
        )
      )
    )
  );

  EntityInfo = {modelName: "product-category"};

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private appStoreEntityService: AppStoreEntityService,
    private productCategoryService: ProductCategoryService,
    private router: Router,
  ) {
  }
}
