// permission.guard.ts
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Permissions} from "src/app/auth/enums/permissions.enum";
import {AppUserState} from "src/app/store/app-user/app-user.state";
import {AppUserFacade} from "src/app/store/app-user/app-user.facade";

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const appStore = inject(Store<AppUserState>);
  const authService = inject(AppUserFacade);
  const router = inject(Router);
  const requiredPermission = route.data['requiredPermission'] as Permissions;
  const adminOnly = route.data['adminOnly'] || false;
  const isLoginPage = route.routeConfig?.path === 'login';

  return appStore.select(state => state.appUser.currentUser).pipe(
    take(1),
    map(currentUser => {
      if (!currentUser) {
        if (isLoginPage) {
          return true;
        }
        return router.createUrlTree(['/login']);
      }

      //If view requires no permission we allow.
      if (!requiredPermission)
        return true;

      if (isLoginPage) {
        return router.createUrlTree(['/products']);
      }

      if (adminOnly && authService.hasRole("Admin") || authService.hasPermission(requiredPermission)) {
        return true;
      }

      return router.createUrlTree(['/unauthorized']);
    })
  );
};
