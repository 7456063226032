import {createSelector} from '@ngrx/store';
import {checkoutEntityAdapter as EntityAdapter, CheckoutState as EntityState} from "./checkout.state";
import {Checkout as Entity} from './checkout.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.checkout;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
    createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
    selectState,
    (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const activeCheckout = createSelector(selectState, (state: EntityState) => state.activeCheckout);

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);

export const CheckoutSelectors = {
    selectAll,
    selectById,
    isLoading,
    hasErrors,
    findByCorrelationId,
    currentEntity,
    totalPageable,
    activeCheckout,
    currentPage,
    currentSort
};
