import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IEntityInfo} from "src/app/store/app-store.interfaces";
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import {environment} from 'src/environments/environment';
import { Criteria } from "src/app/store/app-store.types";

export interface PaginatedResult<T> {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: T[];
  pageIndex: number;
  totalCount: number;
  totalPages: number;
}

@Injectable()
export class AppStoreEntityService {
  constructor(private http: HttpClient) {
  }

  clone<T>(entityInfo: IEntityInfo, id: number | string, criteria?: Criteria): Observable<T> {
    return this.http.get<T>(`${environment.rootUrl}/${entityInfo.modelName}/clone/${id}`
    );
  }

  loadSingle<T>(entityInfo: IEntityInfo, id: number | string): Observable<T> {
    return this.http.get<T>(
      `${
        environment.rootUrl
      }/${entityInfo.modelName}/${id}`
    );
  }

  load<T>(entityInfo: IEntityInfo, criteria?: Criteria): Observable<T[]> {
    if (criteria) {
      return this.http.post<PaginatedResult<T>>(
        `${
          environment.rootUrl
        }/${entityInfo.modelName}/select`,
        {filter: criteria, pageNumber: 0, pageSize: 0},
      ).pipe(
        map(({items}) => items)
      );
    }

    return this.http.get<PaginatedResult<T>>(
      `${environment.rootUrl}/${entityInfo.modelName}`,
    ).pipe(
      map(({items}) => (items)),
    );
  }

  loadMany<T>(entityInfo: IEntityInfo, criteria?: Criteria): Observable<T[]> {
    if (criteria) {
      return this.http.post<PaginatedResult<T>>(
        `${
          environment.rootUrl
        }/${entityInfo.modelName}/select`,
        {filter: criteria, pageNumber: 0, pageSize: 0},
      ).pipe(
        map(({items}) => (items))
      );
    }

    return this.http.get<PaginatedResult<T>>(
      `${environment.rootUrl}/${entityInfo.modelName}`,
    ).pipe(
      map(({items}) => (items))
    );
  }

  loadPage<T>(entityInfo: IEntityInfo, page: IPage, criteria?: Criteria): Observable<IResultWithPageInfo<T>> {
    let call: Observable<PaginatedResult<T>>;

    if (criteria) {
      call = this.http.post<PaginatedResult<T>>(`${environment.rootUrl}/${(entityInfo.uriName ?? entityInfo.modelName)}/select`,
        {filter: criteria, pageNumber: page.pageNumber, pageSize: page.pageSize});
    } else {
      call = this.http.get<PaginatedResult<T>>(`${environment.rootUrl}/${entityInfo.modelName}`, {
        params: {
          skip: page.pageNumber,
          take: page.pageSize
        }
      });
    }

    return call.pipe(
      map(({items, totalCount}) => ({
        pageInfo: {
          page: page,
          totalCount
        },
        entities: items
      }))
    );
  }

  create<T>(entityInfo: IEntityInfo, entity: T): Observable<T> {
    return this.http.post<T>(
      `${environment.rootUrl}/${entityInfo.modelName}`,
      entity,
    );
  }

  createMany<T>(entityInfo: IEntityInfo, entities: T): Observable<T> {
    return this.http.post<T>(
      `${
        environment.rootUrl
      }/${entityInfo.modelName}/create-many/`,
      entities,
    );
  }

  update<T>(entityInfo: IEntityInfo, entity: T): Observable<T> {
    return this.http.patch<T>(
      `${environment.rootUrl}/${entityInfo.modelName}`,
      entity,
    );
  }

  updateMany<T>(entityInfo: IEntityInfo, entities: T): Observable<T> {
    return this.http.patch<T>(
      `${
        environment.rootUrl
      }/${entityInfo.modelName}/update-many/`,
      entities,
    );
  }

  replace<T extends {id: string | number}>(entityInfo: IEntityInfo, entity: T): Observable<T> {
    return this.http.put<T>(
      `${environment.rootUrl}/${entityInfo.modelName}/${
        entity.id
      }`,
      entity,
    );
  }

  remove<T>(entityInfo: IEntityInfo, entityId: number | string): Observable<T> {
    return this.http
      .delete<T>(
        `${environment.rootUrl}/${entityInfo.modelName}/${
          entityId
        }`,
      )
  }

  removeByKey<T>(entityInfo: IEntityInfo, key: number | string): Observable<T> {
    return this.http
      .delete<T>(
        `${environment.rootUrl}/${entityInfo.modelName}/${key}`,
      );
  }

  removeMany<T>(entityInfo: IEntityInfo, ids: number[] | string[]): Observable<T> {
    return this.http
      .delete<T>(`${environment.rootUrl}/${entityInfo.modelName}/delete-many/?id=${ids
          .join('&id=')}`,
      );
  }
}
