import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private router: Router, private snackbar: MatSnackBar) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        this.handleAuthError(error);
        return throwError(error);
      })
    );
  }


  private async handleAuthError(err: HttpErrorResponse): Promise<void> {
    if (err.status === 401 || err.status === 403) {
      this.snackbar.open('Permission denied', undefined,{duration: 5000});

      localStorage.removeItem('token');
      localStorage.removeItem('current_user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('claims');

      await this.router.navigate(['/login']);
    }
    if (err.status === 0) {
      this.snackbar.open('Cannot connect to server', undefined, {duration: 5000});
    }
  }
}
