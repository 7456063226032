import {createAction, props} from '@ngrx/store';
import {UserResource as Entity} from './user-resource.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[UserResource] Clone UserResource',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[UserResource] Clone UserResource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[UserResource] Clone UserResource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[UserResource] Load UserResources',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[UserResource] Load UserResources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[UserResource] Load UserResources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[UserResource] Load Single UserResource if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[UserResource] Load Single UserResource',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[UserResource] Load Single UserResource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[UserResource] Load Single UserResource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[UserResource] Load Paginated UserResources',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[UserResource] Load Paginated UserResources Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[UserResource] Load Paginated UserResources Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const createWithFile = createAction(
  '[UserResource] Create UserResource with file',
  props<{ entity: Entity, file: File, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[UserResource] Create UserResource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[UserResource] Create UserResource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[UserResource] Create Many UserResources',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[UserResource] Create Many UserResources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[UserResource] Create Many UserResources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[UserResource] Update UserResource',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[UserResource] Update UserResource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[UserResource] Update UserResource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[UserResource] Update Many UserResources',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[UserResource] Update Many UserResources Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[UserResource] Update Many UserResources Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[UserResource] Remove UserResource',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[UserResource] Remove UserResource Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[UserResource] Remove UserResource Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[UserResource] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[UserResource] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[UserResource] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[UserResource] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[UserResource] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const getTotalUserResourceCount = createAction(
  '[Product] Get All User Resources'
)

export const updateTotalUserResourceCount = createAction(
  '[Product] Update Total User Resource Count',
  props<{ count: number }>()
);

export const getTotalUserResourceCountFailed = createAction(
  '[Product] Get Total User Resource Count Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const UserResourceActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  createWithFile,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,

  getTotalUserResourceCount,
  updateTotalUserResourceCount,
  getTotalUserResourceCountFailed
};
