import {createAction, props} from '@ngrx/store';
import {AppUser as Entity} from './app-user.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import {TokenInfo} from "src/app/auth/models/auth.model";
import { Criteria } from "src/app/store/app-store.types";


export const signIn = createAction('[AppUser] Sign in AppUser', props<{
  username: string,
  password: string,
  correlationId: string
}>());

export const signInFailure = createAction('[AppUser] Sign in AppUser Failure', props<{
  correlationId: string,
  error: Error | undefined;
}>());

export const signInSuccess = createAction('[AppUser] Sign in AppUser succeeded', props<{
  correlationId: string,
  tokenInfo: TokenInfo;
}>());

export const signInToken = createAction('[AppUser] Sign in AppUser with token', props<{
  token: string,
  userId: number,
  correlationId: string
}>());

export const signInTokenSuccess = createAction('[AppUser] Sign in AppUser with token Success', props<{
  tokenInfo: TokenInfo,
  correlationId: string
}>());

export const signInTokenFailure = createAction('[AppUser] Sign in AppUser with token Failure', props<{
  error: Error,
  correlationId: string
}>());

export const signOut = createAction('[AppUser] Sign out AppUser', props<{
  correlationId: string
}>());

export const signOutFailed = createAction('[AppUser] Sign out AppUser Failed', props<{
  correlationId: string,
  error: Error | undefined;
}>());

export const signOutSuccess = createAction('[AppUser] Sign out AppUser succeeded', props<{
  correlationId: string
}>());

export const clone = createAction(
  '[AppUser] Clone AppUser',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[AppUser] Clone AppUser Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[AppUser] Clone AppUser Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[AppUser] Load AppUsers',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[AppUser] Load AppUsers Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[AppUser] Load AppUsers Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[AppUser] Load Single AppUser',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[AppUser] Load Single AppUser',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[AppUser] Load Single AppUser Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[AppUser] Load Single AppUser Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[AppUser] Load Paginated AppUsers',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[AppUser] Load Paginated AppUsers Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[AppUser] Load Paginated AppUsers Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[AppUser] Create AppUser',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[AppUser] Create AppUser Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[AppUser] Create AppUser Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[AppUser] Create Many AppUsers',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[AppUser] Create Many AppUsers Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[AppUser] Create Many AppUsers Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[AppUser] Update AppUser',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[AppUser] Update AppUser Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[AppUser] Update AppUser Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[AppUser] Update Many AppUsers',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[AppUser] Update Many AppUsers Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[AppUser] Update Many AppUsers Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[AppUser] Remove AppUser',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[AppUser] Remove AppUser Success',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[AppUser] Remove AppUser Failure',
  props<{ error: Error, correlationId?: string }>()
);


export const setCurrentEntity = createAction(
  '[AppUser] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityById = createAction(
  '[AppUser] Set Current Entity By Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[AppUser] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[AppUser] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const AppUserActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  loadSingleIfNecessary,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  signIn,
  signInFailure,
  signInSuccess,
  signOut,
  signOutFailed,
  signOutSuccess,
  signInToken,
  signInTokenSuccess,
  signInTokenFailure,
  setCurrentEntityById,

  setPaginationSettings,
  setSortSettings
};
