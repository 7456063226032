import {createAction, props} from '@ngrx/store';
import {TemplateGroup as Entity} from './template-group.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[TemplateGroup] Clone Template Group',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[TemplateGroup] Clone Template Group Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[TemplateGroup] Clone Template Group Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[TemplateGroup] Load Template Groups',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[TemplateGroup] Load Template Groups Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[TemplateGroup] Load Template Groups Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[TemplateGroup] Load Single Template Group if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[TemplateGroup] Load Single Template Group',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[TemplateGroup] Load Single Template Group Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[TemplateGroup] Load Single Template Group Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[TemplateGroup] Load Paginated Template Groups',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[TemplateGroup] Load Paginated Template Groups Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[TemplateGroup] Load Paginated Template Groups Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[TemplateGroup] Create Template Group',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[TemplateGroup] Create Template Group Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[TemplateGroup] Create Template Group Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[TemplateGroup] Create Many Template Groups',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[TemplateGroup] Create Many Template Groups Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[TemplateGroup] Create Many Template Groups Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[TemplateGroup] Update Template Group',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[TemplateGroup] Update Template Group Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[TemplateGroup] Update Template Group Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[TemplateGroup] Update Many Template Groups',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[TemplateGroup] Update Many Template Groups Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[TemplateGroup] Update Many Template Groups Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[TemplateGroup] Remove Template Group',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[TemplateGroup] Remove Template Group Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[TemplateGroup] Remove Template Group Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[TemplateGroup] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[TemplateGroup] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[TemplateGroup] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[TemplateGroup] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[TemplateGroup] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const TemplateGroupActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary
};
