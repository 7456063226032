import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable} from "rxjs";
import {CheckoutRow} from "./checkout-row.model";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class CheckoutRowService {
    constructor(
        private http: HttpClient,
    ) {
    }

  removeByTemplateId(templateId:number, checkoutId:number): Observable<CheckoutRow[]>{
    console.error(templateId, checkoutId)
      return this.http.post<CheckoutRow[]>(
        `${environment.rootUrl}/checkout-row/delete-many-by-template-id`,
        {templateId, checkoutId}
      );
  }
}
