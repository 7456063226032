import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Checkout as Entity, OrderResult} from "./checkout.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface CheckoutState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntityId: number | undefined,
  history: { correlationId: string, payload: Entity | Entity[] | number | IResultWithPageInfo<Entity> | OrderResult}[];
  activeCheckout: Entity | undefined;
}

export const checkoutEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: CheckoutState = checkoutEntityAdapter.getInitialState({
  entities: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
  currentSort: undefined,
  history: [],
  currentEntityId: undefined,
  activeCheckout: undefined
});
