import {createSelector} from '@ngrx/store';
import {templateEntityAdapter as EntityAdapter, TemplateState as EntityState} from "./template.state";
import {Template as Entity} from './template.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.template;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const currentPageEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentPageEntities
    ? state.currentPageEntities.map(id => state.entities[id]).filter(y => y !== undefined && y.id !== state.currentEntityId)
    : undefined;
});

export const selectStoreChanges = createSelector(selectState, (state: EntityState) => {
  return state.entitiesTemporary
});

export const currentEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityIds
    ? state.currentEntityIds.map(id => state.entities[id]).filter(y => y !== undefined)
    : undefined;
});

export const currentRelatedEntities = createSelector(
  selectState, (state: EntityState) => state.currentRelatedEntities);

export const selectProductCategoryId = createSelector(selectState, (state: EntityState) => state.currentProductCategoryId);

export const selectTemporaryById = (tempId: number) =>
  createSelector(selectStoreChanges, (entities: Entity[]) => entities.find(p => p.tempId === tempId));

export const selectTemporaryByProductId = (productId: number) =>
  createSelector(selectStoreChanges, (entities: Entity[]) => entities.find(p => p.productId === productId));

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);

export const currentRelatedEntitiesTotalPageable = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentPage.totalCount);
export const currentRelatedEntitiesCurrentPage = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentPage.page);
export const currentRelatedEntitiesCurrentSort = createSelector(selectState, (state: EntityState) => state.currentRelatedEntitiesCurrentSort);


export const TemplateSelectors = {
  selectAll,
  selectById,
  isLoading,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  currentEntityId,
  totalPageable,
  currentPage,
  currentSort,
  currentEntities,
  currentPageEntities,
  selectStoreChanges,
  selectTemporaryById,
  selectTemporaryByProductId,
  selectProductCategoryId,
  currentRelatedEntities,
  currentRelatedEntitiesCurrentPage,
  currentRelatedEntitiesCurrentSort,
  currentRelatedEntitiesTotalPageable,
};
