import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Resource} from "./resource.model";

@Injectable({providedIn: 'root'})
export class ResourceService {
    constructor(private http: HttpClient) {
    }

    clone(id: string, cloneProducts: boolean): Observable<Resource> {
        return this.http.get<Resource>(
            `${environment.rootUrl}/resource/clone/${id}/${cloneProducts}`
        );
    }
}
