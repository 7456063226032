import {createReducer, on} from "@ngrx/store";
import {productEntityAdapter as EntityAdapter, initialState} from "./product-base.state";
import {ProductBaseActions as Actions} from "./product-base.actions";

export const productBaseReducer = createReducer(
  initialState,

  on(Actions.load, Actions.loadAndSelect, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId
  })),

  on(Actions.loadSuccess, (state, {entities, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.setAll(entities, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.setAll(entities, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}]
      });
    }
  ),

  on(Actions.loadFailure,  Actions.loadAndSelectFailure,(state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),


  on(Actions.loadAndSelectSuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
        return EntityAdapter.setOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
          currentEntityId: entity.id
        });

      return EntityAdapter.setOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        currentEntityId:entity.id,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),


  on(Actions.create, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.addOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.addOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),

  on(Actions.createFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.createMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.addMany(entities, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.addMany(entities, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entities}],
    });
  }),

  on(Actions.createManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),


  on(Actions.update, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.updateOne(
          {id: entity.id, changes: entity},
          {
            ...state,
            isLoading: false,
            error: undefined,
          });

      return EntityAdapter.updateOne(
        {id: entity.id, changes: entity},
        {
          ...state,
          isLoading: false,
          error: undefined,
          correlationId,
          history: [...state.history, {correlationId, payload: entity}]
        });
    }
  ),

  on(Actions.updateFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.updateMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.updateMany(
        entities.map(entity => ({id: entity.id, changes: entity})),
        {
          ...state,
          isLoading: false,
          error: undefined,
        });

    return EntityAdapter.updateMany(
      entities.map(entity => ({id: entity.id, changes: entity})),
      {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}],
      });
  }),

  on(Actions.updateManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.remove, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.removeSuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.removeOne(entity.id, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.removeOne(entity.id, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entity}]
    });
  }),

  on(Actions.removeFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.loadPage, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.loadProductBasePageForEditor, (state, {correlationId}) => ({
    ...state,
    isLoadingEditor$: true,
    correlationId,
  })),

  on(Actions.loadProductBasePageForEditorSuccess, (state, {resultWithPageInfo, correlationId}) => {
    if (!correlationId)
      return ({
        ...state,
        isLoadingEditor$: false,
        error: undefined,
        selectForEditorEntities: resultWithPageInfo.entities,
        selectForEditorEntitiesCurrentPage: resultWithPageInfo.pageInfo,
      });

      return ({
      ...state,
      isLoadingEditor$: false,
      error: undefined,
      selectForEditorEntities: resultWithPageInfo.entities,
      history: [...state.history, {correlationId, payload: resultWithPageInfo}],
      selectForEditorEntitiesCurrentPage: resultWithPageInfo.pageInfo,
    });
  }),

  on(Actions.loadPageSuccess, (state, {resultWithPageInfo, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.setAll(resultWithPageInfo.entities, {
        ...state,
        isLoading: false,
        error: undefined,
        currentPage: resultWithPageInfo.pageInfo,
        currentPageEntities: resultWithPageInfo.entities.map(entity => entity.id),
      });

    return EntityAdapter.setAll(resultWithPageInfo.entities, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      currentPage: resultWithPageInfo.pageInfo,
      currentPageEntities: resultWithPageInfo.entities.map(entity => entity.id),
      history: [...state.history, {correlationId, payload: resultWithPageInfo}],
    });
  }),

  on(Actions.loadPageFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.setCurrentEntityId, (state, {entityId}) => ({
    ...state,
    currentEntityId: entityId,
  })),

  on(Actions.deselectEntity, (state, {entityId}) => {
    return {
      ...state,
      currentEntityIds: state.currentEntityIds?.filter(id => id !== entityId),
    }
  }),

  on(Actions.selectEntity, (state, {entityId}) => {
    return {
      ...state,
      currentEntityIds: state.currentEntityIds?.includes(entityId) ? state.currentEntityIds : [
        ...state.currentEntityIds ?? [],
        entityId]
    }
  }),

  on(Actions.deselectEntities, (state, {}) => ({
      ...state,
      currentEntityIds: [],
      currentEntityId: undefined,
    })
  ),

  on(Actions.setPaginationSettings, (state, {page}) => ({
    ...state,
    currentPage: { totalCount: state.currentPage?.totalCount, page},
  })),

    on(Actions.setPaginationSettingsForEditor, (state, {page}) => ({
        ...state,
        selectForEditorEntitiesCurrentPage: { totalCount: state.selectForEditorEntitiesCurrentPage?.totalCount, page},
    })),

  on(Actions.updateTotalProductCount, (state, { count}) => ({
    ...state,
    totalCount: count
  })),

  on(Actions.clearPage, (state, {}) => ({
    ...state,
    currentPageEntities: initialState.currentPageEntities,
    currentPage: initialState.currentPage,
  })),
);
