import {createAction, props} from '@ngrx/store';
import {ProductPlacement as Entity} from './product-placement.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[ProductPlacement] Clone Entity',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ProductPlacement] Clone Entity Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ProductPlacement] Clone Entity Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[ProductPlacement] Load Entitys',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ProductPlacement] Load Entitys Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ProductPlacement] Load Entitys Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[ProductPlacement] Load Single Entity if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[ProductPlacement] Load Single Entity',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[ProductPlacement] Load Single Entity Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ProductPlacement] Load Single Entity Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ProductPlacement] Load Paginated Entitys',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[ProductPlacement] Load Paginated Entitys Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const createWithResource = createAction(
  '[UserResource] Create ProductPlacement with resource',
  props<{ entity: Entity, file: File, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ProductPlacement] Load Paginated Entitys Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[ProductPlacement] Create Entity',
  props<{ entity: Entity, correlationId?: string }>()
);

export const clearStore = createAction(
  '[ProductPlacement] Clear Store Entity'
);

export const createInStore = createAction(
  '[ProductPlacement] Create In Store Entity',
  props<{ entity: Entity }>()
);

export const updateInStore = createAction(
  '[ProductPlacement] Update In Store Entity',
  props<{ entity: Entity }>()
);

export const removeInStore = createAction(
  '[ProductPlacement] Remove In Store Entity',
  props<{ entity: Entity }>()
);

export const saveStoreChanges = createAction(
  '[ProductPlacement] Save store changes',
  props<{ correlationId?: string }>()
);

export const saveStoreChangesSuccess = createAction(
  '[ProductPlacement] Save store changes success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const saveStoreChangesFailure = createAction(
  '[ProductPlacement] Save store changes failure',
  props<{  error: Error, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ProductPlacement] Create Entity Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ProductPlacement] Create Entity Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[ProductPlacement] Create Many Entitys',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ProductPlacement] Create Many Entitys Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ProductPlacement] Create Many Entitys Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[ProductPlacement] Update Entity',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ProductPlacement] Update Entity Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ProductPlacement] Update Entity Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ProductPlacement] Update Many Entitys',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ProductPlacement] Update Many Entitys Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ProductPlacement] Update Many Entitys Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[ProductPlacement] Remove Entity',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ProductPlacement] Remove Entity Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ProductPlacement] Remove Entity Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ProductPlacement] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[ProductPlacement] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ProductPlacement] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[ProductPlacement] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ProductPlacement] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const ProductPlacementActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,
  createWithResource,

  createInStore,
  updateInStore,
  removeInStore,
  saveStoreChanges,
  saveStoreChangesSuccess,
  saveStoreChangesFailure,
  clearStore
};
