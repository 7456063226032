import {createReducer, on} from "@ngrx/store";
import {productEntityAdapter as EntityAdapter, initialState} from "./product.state";
import {ProductActions as Actions} from "./product.actions";

export const productReducer = createReducer(
  initialState,

  on(Actions.load, Actions.loadAndSelect, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId
  })),

  on(Actions.loadSuccess, (state, {entities, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.setAll(entities, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.setAll(entities, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}]
      });
    }
  ),

  on(Actions.addToStore, (state, {entities, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.setMany(entities, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.setMany(entities, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}]
      });
    }
  ),

  on(Actions.loadFailure,  Actions.loadAndSelectFailure,(state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),


  on(Actions.loadAndSelectSuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
        return EntityAdapter.addOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
          currentEntityId: entity.id,
          currentEntityIds: state.currentEntityIds?.includes(entity.id) ? state.currentEntityIds : [
            ...state.currentEntityIds ?? [],
            entity.id]
        });

      return EntityAdapter.addOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        currentEntityId:entity.id,
        history: [...state.history, {correlationId, payload: entity}],
        currentEntityIds: state.currentEntityIds?.includes(entity.id) ? state.currentEntityIds : [
          ...state.currentEntityIds ?? [],
          entity.id]
      });
    }
  ),


  on(Actions.create, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.addOne(entity, {
          ...state,
          isLoading: false,
          error: undefined,
        });

      return EntityAdapter.addOne(entity, {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entity}]
      });
    }
  ),

  on(Actions.createFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.createMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.createManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.addMany(entities, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.addMany(entities, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entities}],
    });
  }),

  on(Actions.createManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),


  on(Actions.update, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateSuccess, (state, {entity, correlationId}) => {
      if (!correlationId)
        return EntityAdapter.updateOne(
          {id: entity.id, changes: entity},
          {
            ...state,
            isLoading: false,
            error: undefined,
          });

      return EntityAdapter.updateOne(
        {id: entity.id, changes: entity},
        {
          ...state,
          isLoading: false,
          error: undefined,
          correlationId,
          history: [...state.history, {correlationId, payload: entity}]
        });
    }
  ),

  on(Actions.updateFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.updateMany, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.updateManySuccess, (state, {entities, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.updateMany(
        entities.map(entity => ({id: entity.id, changes: entity})),
        {
          ...state,
          isLoading: false,
          error: undefined,
        });

    return EntityAdapter.updateMany(
      entities.map(entity => ({id: entity.id, changes: entity})),
      {
        ...state,
        isLoading: false,
        error: undefined,
        correlationId,
        history: [...state.history, {correlationId, payload: entities}],
      });
  }),

  on(Actions.updateManyFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.remove, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.removeSuccess, (state, {entity, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.removeOne(entity.id, {
        ...state,
        isLoading: false,
        error: undefined,
      });

    return EntityAdapter.removeOne(entity.id, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      history: [...state.history, {correlationId, payload: entity}]
    });
  }),

  on(Actions.removeFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.loadPage, (state, {correlationId}) => ({
    ...state,
    isLoading: true,
    correlationId,
  })),

  on(Actions.loadPageSuccess, (state, {resultWithPageInfo, correlationId}) => {
    if (!correlationId)
      return EntityAdapter.setAll(resultWithPageInfo.entities, {
        ...state,
        isLoading: false,
        error: undefined,
        currentPage: resultWithPageInfo.pageInfo,
      });

    return EntityAdapter.setAll(resultWithPageInfo.entities, {
      ...state,
      isLoading: false,
      error: undefined,
      correlationId,
      currentPage: resultWithPageInfo.pageInfo,
      history: [...state.history, {correlationId, payload: resultWithPageInfo}],
    });
  }),

  on(Actions.loadPageFailure, (state, {error, correlationId}) => ({
    ...state,
    isLoading: false,
    error,
    correlationId,
  })),

  on(Actions.setCurrentEntityId, (state, { entityId }) => {
    // Update state only if entityId is different
    if (state.currentEntityId !== entityId) {
      return {
        ...state,
        currentEntityId: entityId,
      };
    }
    // Return the state as is if there's no change
    return state;
  }),

  on(Actions.deselectAll, (state) => ({ ...state, currentEntityIds: [] })),

  on(Actions.deselectEntities, (state, { entities }) => {
    // Assuming 'entities' is an array of entities, extract their IDs.
    const entityIdsToRemove = entities.map(entity => entity.id);

    // Use the EntityAdapter to remove the entities from the state
    const newState = EntityAdapter.removeMany(entityIdsToRemove, state);

    // Filter out the deselected entity IDs from currentEntityIds
    const updatedCurrentEntityIds = state.currentEntityIds?.filter(id => !entityIdsToRemove.includes(id));

    return {
      ...newState, // newState already incorporates the changes made by removeMany
      currentEntityIds: updatedCurrentEntityIds, // Overwrite currentEntityIds with the filtered list
    };
  }),


  on(Actions.selectEntities, (state, { entities }) => {
    // Use the EntityAdapter to set the entities in the state
    const newState = EntityAdapter.setMany(entities, state);

    // Extract the IDs from the entities
    const entityIds = entities.map(entity => entity.id);
    // Create a new Set from the existing currentEntityIds to ensure uniqueness
    const currentEntityIdsSet = new Set(newState.currentEntityIds);

    // Add each new entityId to the Set if it doesn't already exist in the state
    entityIds.forEach(entityId => {
      currentEntityIdsSet.add(entityId); // Set will automatically ignore duplicates
    });

    // Convert the Set back to an array for the new state's currentEntityIds
    const newCurrentEntityIds = Array.from(currentEntityIdsSet);

    return {
      ...newState,
      currentEntityIds: newCurrentEntityIds
    };
  }),

  on(Actions.selectEntity, (state, { entityIds }) => {
    // Create a new Set from the existing state to ensure uniqueness
    const currentEntityIdsSet = new Set(state.currentEntityIds);

    // Add each new entityId to the Set if it doesn't already exist in the state
    entityIds.forEach(entityId => {
      if (!currentEntityIdsSet.has(entityId)) {
        currentEntityIdsSet.add(entityId);
      }
    });

    // Convert the Set back to an array for the new state
    const newCurrentEntityIds = Array.from(currentEntityIdsSet);

    return {
      ...state,
      currentEntityIds: newCurrentEntityIds
    };
  }),

  on(Actions.deselectEntities, (state, { entities }) => {
    // Create a Set from the currentEntityIds for easy removal
    const updatedEntityIdsSet = new Set(state.currentEntityIds);

    // Remove each entityId that needs to be deselected
    entities.forEach(entity => {
      updatedEntityIdsSet.delete(entity.id);
    });

    // Convert the Set back to an array for the new state
    const updatedCurrentEntityIds = Array.from(updatedEntityIdsSet);

    return {
      ...state,
      currentEntityIds: updatedCurrentEntityIds,
      // Set currentEntityId to undefined if it's in the deselected list
      currentEntityId: entities.some(entity => entity.id === state.currentEntityId) ? undefined : state.currentEntityId,
    };
  }),

  on(Actions.setPaginationSettings, (state, {page}) => ({
    ...state,
    currentPage: { totalCount: state.currentPage?.totalCount, page},
  })),

  on(Actions.updateTotalProductCount, (state, { count}) => ({
    ...state,
    totalCount: count
  })),

  on(Actions.getTotalProductColoursSuccess, (state, { productColours}) => ({
    ...state,
    currentColours: productColours
  })),
);
