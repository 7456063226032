import {createAction, props} from '@ngrx/store';
import {TemplatePreview as Entity} from './template-preview.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[TemplatePreview] Clone TemplatePreview',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[TemplatePreview] Clone TemplatePreview Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[TemplatePreview] Clone TemplatePreview Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[TemplatePreview] Load TemplatePreviews',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[TemplatePreview] Load TemplatePreviews Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[TemplatePreview] Load TemplatePreviews Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[TemplatePreview] Load Single TemplatePreview if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[TemplatePreview] Load Single TemplatePreview',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[TemplatePreview] Load Single TemplatePreview Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[TemplatePreview] Load Single TemplatePreview Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[TemplatePreview] Load Paginated TemplatePreviews',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[TemplatePreview] Load Paginated TemplatePreviews Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[TemplatePreview] Load Paginated TemplatePreviews Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[TemplatePreview] Create TemplatePreview',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[TemplatePreview] Create TemplatePreview Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[TemplatePreview] Create TemplatePreview Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[TemplatePreview] Create Many TemplatePreviews',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[TemplatePreview] Create Many TemplatePreviews Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[TemplatePreview] Create Many TemplatePreviews Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[TemplatePreview] Update TemplatePreview',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[TemplatePreview] Update TemplatePreview Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[TemplatePreview] Update TemplatePreview Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[TemplatePreview] Update Many TemplatePreviews',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[TemplatePreview] Update Many TemplatePreviews Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[TemplatePreview] Update Many TemplatePreviews Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[TemplatePreview] Remove TemplatePreview',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[TemplatePreview] Remove TemplatePreview Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[TemplatePreview] Remove TemplatePreview Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[TemplatePreview] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[TemplatePreview] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[TemplatePreview] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[TemplatePreview] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[TemplatePreview] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const TemplatePreviewActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary
};
