import {createAction, props} from '@ngrx/store';
import {ProductBaseCategory as Entity} from './product-base-category.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[ProductBaseCategory] Clone Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ProductBaseCategory] Clone Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ProductBaseCategory] Clone Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadIfNecessary = createAction(
  '[ProductBaseCategory] Load Templates If Necessary',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccessIfNecessary = createAction(
  '[ProductBaseCategory] Load Templates Success If Necessary',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailureIfNecessary = createAction(
  '[ProductBaseCategory] Load Templates Failure If Necessary',
  props<{ error: Error, correlationId?: string }>()
);


export const load = createAction(
  '[ProductBaseCategory] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ProductBaseCategory] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ProductBaseCategory] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[ProductBaseCategory] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[ProductBaseCategory] Load Single Template',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[ProductBaseCategory] Load Single Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ProductBaseCategory] Load Single Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ProductBaseCategory] Load Paginated Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[ProductBaseCategory] Load Paginated Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ProductBaseCategory] Load Paginated Templates Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[ProductBaseCategory] Create Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ProductBaseCategory] Create Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ProductBaseCategory] Create Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[ProductBaseCategory] Create Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ProductBaseCategory] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ProductBaseCategory] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[ProductBaseCategory] Update Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ProductBaseCategory] Update Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ProductBaseCategory] Update Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ProductBaseCategory] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ProductBaseCategory] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ProductBaseCategory] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[ProductBaseCategory] Remove Template',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ProductBaseCategory] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ProductBaseCategory] Remove Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ProductBaseCategory] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[ProductBaseCategory] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ProductBaseCategory] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[ProductBaseCategory] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ProductBaseCategory] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const getTotalProductBaseCategoryCount = createAction(
  '[ProductBaseCategory] Get All Product Categories'
)

export const updateTotalProductBaseCategoryCount = createAction(
  '[ProductBaseCategory] Update Total Product Category Count',
  props<{ count: number }>()
);

export const getTotalProductBaseCategoryCountFailed = createAction(
  '[ProductBaseCategory] Get Total Product Category Count Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const ProductBaseCategoryActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,

  getTotalProductBaseCategoryCount,
  updateTotalProductBaseCategoryCount,
  getTotalProductBaseCategoryCountFailed,

  loadIfNecessary,
  loadSuccessIfNecessary,
  loadFailureIfNecessary,
};
