import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {TemplateTag} from "./template-tag.model";

@Injectable({providedIn: 'root'})
export class TemplateTagService {
    constructor(private http: HttpClient) {
    }

    clone(id: number, cloneProducts: boolean): Observable<TemplateTag> {
        return this.http.get<TemplateTag>(
            `${environment.rootUrl}/template-tag/clone/${id}/${cloneProducts}`
        );
    }
}
