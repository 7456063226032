import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const brandie: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BrandieClient';
  environment = environment.production
}
