// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  rootUrl: 'api',
  resourceUrl: '',
  applicationKey: '7495FD08-0E61-40E9-AA74-E6B35DBFC46A',
  storageTokenKey: 'brandie-token',
  storageUserKey: 'brandie-user',
  tokenWhiteListedDomains: [''],
  marketUrl: 'https://market.acgaccent.com/Views/Start/Welcome.aspx',
  SCALE: 0.5,
  PIX_TO_MM_RATIO: 2,
  MM_TO_PIX_RATIO: 0.5,
  cloudFlare: 'https://dev.cdn.acgaccent.io/',
  resourceBlobEndpoint: '/brandie/{accessId}/{type}/{previewName}',
  resourceBlobEndpointNoFileType: 'brandie/{accessId}/{type}/{previewName}',
  resourceArticleEndpointNoFileType: 'articles/{previewName}',
  resourceArticleEndpoint: '/articles/{previewName}',
  defaultNoImage: 'brandie/default/placeholder_landscape.svg',
  defaultCreatingPreview: 'brandie/default/creating_preview_placeholder.svg'
};
