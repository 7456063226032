import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {AppClaim as Entity} from "./app-claim.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";



export interface AppClaimState extends EntityState<Entity> {
    entities: { [id: number]: Entity };
    ids: number[];
    isLoading: boolean;
    error: Error | undefined;
    currentPage: IPageInfo;
    currentEntity: Entity | undefined,
    history: { correlationId: string, payload: Entity | Entity[] | number | IResultWithPageInfo<Entity>}[];
}

export const appClaimEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
    {
        selectId: (product: Entity) => product.id
    }
);

export const initialState: AppClaimState = appClaimEntityAdapter.getInitialState({
    entities: [],
    ids: [],
    isLoading: false,
    error: undefined,
    currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
    currentEntity: undefined,
    history: []
});
