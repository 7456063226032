import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Template} from "./template.model";
import {IEntityInfo} from "../app-store.interfaces";

@Injectable({providedIn: 'root'})
export class TemplateService {
    constructor(private http: HttpClient) {
    }

    clone(id: number, cloneProducts: boolean): Observable<Template> {
        return this.http.get<Template>(
            `${environment.rootUrl}/template/clone/${id}/${cloneProducts}`
        );
    }

    hasProducts(templateId: number): Observable<boolean> {
        return this.http
            .get<boolean>(`${environment.rootUrl}/template/has-products/${templateId}`)
            .pipe(first());
    }

  createManyTemplates( templates: Template[], createNewCategory: boolean ): Observable<Template[]> {
    return this.http.post<Template[]>(
      `${environment.rootUrl}/template/create-many-templates/`, {templates, createNewCategory});
  }
}
