import { UserResource as Entity } from './user-resource.model';
import { AppState } from '../app-store.state';
import { Injectable } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {IPage, ITableSort} from "../app-store.interfaces";
import {UserResourceSelectors as Selectors} from "./user-resource.selectors";
import {UserResourceActions as Actions} from './user-resource.actions';
import {UserResourceService} from "./user-resource.service";
import {Criteria} from "../app-store.types";
import {generateUniqueId} from "../app-store.functions";
import {PaginatedResult} from "../app-store-entity.service";
import {filter, first} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class UserResourceFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<Error | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  currentId$: Observable<number | undefined> = this.store.pipe(select(Selectors.currentEntityId));
  totalPageable$: Observable<number> = this.store.pipe(select(Selectors.totalPageable));
  currentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));

  private _refreshPage = new Subject<void>();
  refreshPage$: Observable<void> = this._refreshPage.asObservable();

  constructor(
    private store: Store<AppState>,
    private userResourceService: UserResourceService,
  ) {

  }

  deselect(): void {
    this.store.dispatch(Actions.deselectEntities());
  }

  refreshPage(): void {
    this._refreshPage.next();
  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
    this.refreshPage();
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  setCurrentId(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityId({entityId}));
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.load({criteria}));
  }

  loadWithResult(criteria: Criteria = undefined): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({criteria, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadSingleWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadSingleIfNecessary({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x), first());
  }


  create(entity: Entity, file: File): void {
    this.store.dispatch(Actions.createWithFile({entity, file}));
  }

  createWithResult(entity: Entity, file: File): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createWithFile({entity, file, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createMany(entities: Entity[]): void {
    this.store.dispatch(Actions.createMany({entities}));
  }

  createManyWithResult(entities: Entity[], files: File[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }

  removeWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.remove({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }

  getTotalCount(): Observable<number> {
    return this.store.select(Selectors.totalCount);
  }

  setTotalCount(): void {
    this.store.dispatch(Actions.getTotalUserResourceCount());
  }
}
