import {createAction, props} from '@ngrx/store';
import {Article as Entity} from './article.model';
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[Article] Clone Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[Article] Clone Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[Article] Clone Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[Article] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[Article] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[Article] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[Article] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[Article] Load Single Template',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[Article] Load Single Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[Article] Load Single Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[Article] Load Paginated Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[Article] Load Paginated Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[Article] Load Paginated Templates Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[Article] Create Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[Article] Create Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[Article] Create Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[Article] Create Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[Article] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[Article] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[Article] Update Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[Article] Update Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[Article] Update Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[Article] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[Article] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[Article] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[Article] Remove Template',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[Article] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[Article] Remove Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[Article] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[Article] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[Article] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[Article] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[Article] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const ArticleActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary
};
