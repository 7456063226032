import {createAction, props} from '@ngrx/store';
import {AppClaim as Entity} from './app-claim.model';
import {Claim} from "src/app/store/claim/claim.model";
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";

export const clone = createAction(
  '[AppClaim] Clone AppClaim',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[AppClaim] Clone AppClaim Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[AppClaim] Clone AppClaim Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[AppClaim] Load AppClaims',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[AppClaim] Load AppClaims Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[AppClaim] Load AppClaims Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[AppClaim] Load Single AppClaim',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[AppClaim] Load Single AppClaim Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[AppClaim] Load Single AppClaim Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[AppClaim] Load Paginated AppClaims',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[AppClaim] Load Paginated AppClaims Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[AppClaim] Load Paginated AppClaims Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[AppClaim] Create AppClaim',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[AppClaim] Create AppClaim Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[AppClaim] Create AppClaim Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[AppClaim] Create Many AppClaims',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[AppClaim] Create Many AppClaims Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[AppClaim] Create Many AppClaims Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[AppClaim] Update AppClaim',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[AppClaim] Update AppClaim Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[AppClaim] Update AppClaim Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[AppClaim] Update Many AppClaims',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[AppClaim] Update Many AppClaims Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[AppClaim] Update Many AppClaims Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[AppClaim] Remove AppClaim',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[AppClaim] Remove AppClaim Success',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[AppClaim] Remove AppClaim Failure',
  props<{ error: Error, correlationId?: string }>()
);


export const setCurrentEntity = createAction(
  '[AppClaim] Set Current Entity',
  props<{ entity: Entity }>()
);


export const setClaimsForRole = createAction(
  '[AppClaim] Set Claims For Role',
  props<{ roleId: number, claims: Claim[], correlationId?: string }>()
);

export const setClaimsForRoleSuccess = createAction(
  '[AppClaim] Set Claims For Role Success',
  props<{ claims: Claim[], correlationId?: string }>()
);

export const setClaimsForRoleFailure = createAction(
  '[AppClaim] Set Claims For Role Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const getClaimsForRole = createAction(
  '[AppClaim] Get Claims For Role',
  props<{ roleId: number, correlationId?: string }>()
);

export const getClaimsForRoleSuccess = createAction(
  '[AppClaim] Get Claims For Role Success',
  props<{ claims: Claim[], correlationId?: string }>()
);

export const getClaimsForRoleFailure = createAction(
  '[AppClaim] Get Claims For Role Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const AppClaimActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,
  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setClaimsForRole,
  setClaimsForRoleSuccess,
  setClaimsForRoleFailure,
  getClaimsForRoleFailure,
  getClaimsForRoleSuccess,
  getClaimsForRole
};
