import {createAction, props} from '@ngrx/store';
import {ResourceItem as Entity} from './resource-item.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";

import { Criteria } from "src/app/store/app-store.types";


export const clone = createAction(
  '[ResourceItem] Clone ResourceItem',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[ResourceItem] Clone ResourceItem Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[ResourceItem] Clone ResourceItem Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[ResourceItem] Load ResourceItems',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[ResourceItem] Load ResourceItems Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[ResourceItem] Load ResourceItems Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[ResourceItem] Load Single ResourceItem if necessary',
  props<{ entityId: string, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[ResourceItem] Load Single ResourceItem',
  props<{ entityId: string, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[ResourceItem] Load Single ResourceItem Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[ResourceItem] Load Single ResourceItem Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[ResourceItem] Load Paginated ResourceItems',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const clearPage = createAction(
  '[ProductCategory] Clear Page'
);

export const loadPageSuccess = createAction(
  '[ResourceItem] Load Paginated ResourceItems Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[ResourceItem] Load Paginated ResourceItems Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const create = createAction(
  '[ResourceItem] Create ResourceItem',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[ResourceItem] Create ResourceItem Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[ResourceItem] Create ResourceItem Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[ResourceItem] Create Many ResourceItems',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[ResourceItem] Create Many ResourceItems Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[ResourceItem] Create Many ResourceItems Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const makeTransparent = createAction(
  '[ResourceItem] Make transparent',
  props<{ entity: Entity, correlationId?: string }>()
);

export const update = createAction(
  '[ResourceItem] Update ResourceItem',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[ResourceItem] Update ResourceItem Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[ResourceItem] Update ResourceItem Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[ResourceItem] Update Many ResourceItems',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[ResourceItem] Update Many ResourceItems Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[ResourceItem] Update Many ResourceItems Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[ResourceItem] Remove ResourceItem',
  props<{ entityId: string, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[ResourceItem] Remove ResourceItem Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[ResourceItem] Remove ResourceItem Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectEntities = createAction(
  '[ResourceItem] Deselect Current Entity'
);

export const setCurrentEntity = createAction(
  '[ResourceItem] Set Current Entity',
  props<{ entity: Entity }>()
);

export const setCurrentEntityId = createAction(
  '[ResourceItem] Set Current Entity Id',
  props<{ entityId: string }>()
);

export const setPaginationSettings = createAction(
  '[ResourceItem] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[ResourceItem] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const ResourceItemActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,

  setPaginationSettings,
  setSortSettings,
  clearPage,

  loadSingleIfNecessary,
  makeTransparent
};
