import { NgModule } from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {canActivate} from "./auth/guards/auth.guard";
import {LoginComponent} from "./features/login/login.component";

import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Customize this condition as needed
        if (future.routeConfig !== curr.routeConfig || (future.data && future.data["noReuse"])) {
            return false;
        }
        return future.routeConfig === curr.routeConfig;
    }
}


const routes: Routes = [
  {
    path: 'home',
    canActivate: [canActivate],
    loadChildren: () => import('./layout/user-layout/user-layout.module').then((m) => m.UserLayoutModule),
  },

  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: 'sign-in-token',
        component: LoginComponent,
        pathMatch: 'full',
      }
    ],
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./features/unauthorized/unauthorized.module').then(
      (m) => m.UnauthorizedModule,
    ),
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];

const config: ExtraOptions = {
    useHash: false,
    preloadingStrategy: PreloadAllModules
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
    providers: [{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy }]
})
export class AppRoutingModule { }
