import {createAction, props} from '@ngrx/store';
import {Template as Entity} from './template.model';
import {IPage, ITableSort, IResultWithPageInfo} from "src/app/store/app-store.interfaces";
import { Criteria } from "src/app/store/app-store.types";

export const setProductCategoryId = createAction(
  '[Template] Set Product Category Id',
  props<{ productCategoryId: number | undefined }>()
);

export const clearProductCategoryId = createAction(
  '[Template] Clear Product Category Id',
);

export const clearPage = createAction(
  '[ProductBase] Clear Page'
);

export const clearStore = createAction(
  '[Template] Clear Store Entity'
);

export const createInStore = createAction(
  '[Template] Create In Store Entity',
  props<{ entity: Entity }>()
);

export const updateInStore = createAction(
  '[Template] Update In Store Entity',
  props<{ entity: Entity }>()
);

export const removeInStore = createAction(
  '[Template] Remove In Store Entity',
  props<{ entity: Entity }>()
);

export const clone = createAction(
  '[Template] Clone Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneSuccess = createAction(
  '[Template] Clone Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const cloneFailure = createAction(
  '[Template] Clone Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const load = createAction(
  '[Template] Load Templates',
  props<{ criteria?: Criteria, correlationId?: string }>()
);

export const loadSuccess = createAction(
  '[Template] Load Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const loadFailure = createAction(
  '[Template] Load Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadSingleIfNecessary = createAction(
  '[Template] Load Single Template if necessary',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingle = createAction(
  '[Template] Load Single Template',
  props<{ entityId: number, criteria?: Criteria, correlationId?: string }>()
);

export const loadSingleSuccess = createAction(
  '[Template] Load Single Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const loadSingleFailure = createAction(
  '[Template] Load Single Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const loadPage = createAction(
  '[Template] Load Paginated Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadPageSuccess = createAction(
  '[Template] Load Paginated Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const loadPageFailure = createAction(
  '[Template] Load Paginated Templates Failed',
  props<{ error: Error, correlationId?: string }>()
);

export const loadRelatedTemplates = createAction(
  '[ProductCategory] Load Paginated Related Templates',
  props<{ page: IPage, criteria?: Criteria, correlationId?: string }>()
);

export const loadRelatedTemplatesSuccess = createAction(
  '[ProductCategory] Load Paginated Related Templates Success',
  props<{ resultWithPageInfo: IResultWithPageInfo<Entity>, correlationId?: string }>()
);

export const create = createAction(
  '[Template] Create Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createSuccess = createAction(
  '[Template] Create Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const createFailure = createAction(
  '[Template] Create Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const createMany = createAction(
  '[Template] Create Many Templates',
  props<{ entities: Entity[], createNewCategory: boolean, correlationId?: string }>()
);

export const createManySuccess = createAction(
  '[Template] Create Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const createManyFailure = createAction(
  '[Template] Create Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const update = createAction(
  '[Template] Update Template',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateSuccess = createAction(
  '[Template] Update Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const updateFailure = createAction(
  '[Template] Update Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const updateMany = createAction(
  '[Template] Update Many Templates',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManySuccess = createAction(
  '[Template] Update Many Templates Success',
  props<{ entities: Entity[], correlationId?: string }>()
);

export const updateManyFailure = createAction(
  '[Template] Update Many Templates Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const remove = createAction(
  '[Template] Remove Template',
  props<{ entityId: number, correlationId?: string }>()
);

export const removeSuccess = createAction(
  '[Template] Remove Template Success',
  props<{ entity: Entity, correlationId?: string }>()
);

export const removeFailure = createAction(
  '[Template] Remove Template Failure',
  props<{ error: Error, correlationId?: string }>()
);

export const deselectAll = createAction(
  '[Template] Deselect All Entities'
);

export const deselectEntities = createAction(
  '[Template] Deselect Current Entity',
  props<{ entities: Entity[] }>()
);

export const setCurrentEntity = createAction(
  '[Template] Set Current Entity',
  props<{ entity: Entity }>()
);

export const selectEntity = createAction(
  '[Template] Select Entities by Ids',
  props<{ entityIds: number[] }>()
);


export const selectEntities = createAction(
  '[Template] Select Entities',
  props<{ entities: Entity[] }>()
);

export const setCurrentEntityId = createAction(
  '[Template] Set Current Entity Id',
  props<{ entityId: number }>()
);

export const setPaginationSettings = createAction(
  '[Template] Set Pagination Settings',
  props<{
    page: IPage,
  }>()
);

export const setSortSettings = createAction(
  '[Template] Set Sort Settings',
  props<{ tableSort: ITableSort }>()
);

export const TemplateActions = {
  loadPage,
  loadPageSuccess,
  loadPageFailure,
  load,
  loadSuccess,
  loadFailure,
  loadSingle,
  loadSingleSuccess,
  loadSingleFailure,
  loadRelatedTemplates,
  loadRelatedTemplatesSuccess,

  create,
  createSuccess,
  createFailure,
  createMany,
  createManySuccess,
  createManyFailure,
  update,
  updateSuccess,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateManyFailure,
  remove,
  removeSuccess,
  removeFailure,

  clone,
  cloneSuccess,
  cloneFailure,
  setCurrentEntity,
  setCurrentEntityId,
  deselectEntities,
  deselectAll,
  selectEntity,
  selectEntities,

  clearPage,
  setPaginationSettings,
  setSortSettings,

  loadSingleIfNecessary,

  clearStore,
  createInStore,
  updateInStore,
  removeInStore,

  setProductCategoryId,
  clearProductCategoryId
};
