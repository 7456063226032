import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ProductBaseCategory as Entity} from "./product-base-category.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface ProductBaseCategoryState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntityId: number | undefined,
  history: { correlationId: string, payload: Entity | Entity[] | number | number[] | IResultWithPageInfo<Entity> }[];
  totalCount: number;
}

export const productCategoryEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: ProductBaseCategoryState = productCategoryEntityAdapter.getInitialState({
  entities: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
  currentSort: undefined,
  currentEntityId: undefined,
  history: [],
  totalCount: 0,
});
