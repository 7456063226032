import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {AppUser, AppUser as Entity} from './app-user.model';
import {AppUserActions as Actions} from './app-user.actions';
import {AppUserSelectors as Selectors} from "./app-user.selectors";
import {AppState} from "src/app/store/app-store.state";
import {generateUniqueId} from "src/app/store/app-store.functions";
import {PaginatedResult} from "src/app/store/app-store-entity.service";
import {filter, first, map} from "rxjs/operators";
import {Permissions} from "src/app/auth/enums/permissions.enum";
import { Criteria } from "src/app/store/app-store.types";
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";
import {PermissionPipe} from "../../auth/pipes/permission.pipe";

@Injectable({providedIn: 'root'})
export class AppUserFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<boolean | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  currentUser$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentUser));
  totalPageable$: Observable<number | undefined> = this.store.pipe(select(Selectors.totalPageable));
  currentRoleNames$: Observable<string[] | undefined> = this.store.pipe(select(Selectors.currentRoleNames));
  currentPage$: Observable<IPage | undefined> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));

  constructor(private store: Store<AppState>) {
  }

  hasRole(roleName: string): Observable<boolean>{
    return this.currentRoleNames$.pipe(
      filter(x => x !== undefined),
      map(x => x?.includes(roleName)),
      first()
    ) as Observable<boolean>;
  }

  currentUserName(): Observable<string> {
    return this.currentUser$.pipe(
      filter(x => !!x),
      map(x => {
        const nameParts = x?.fullName?.split(' ');

        if(nameParts === undefined)
          return ''

        const firstName = nameParts?.[0]?.charAt(0);
        const lastName = nameParts?.[1];

        return `${firstName} ${lastName}` ?? '';
      }),
      first()
    );
  }

  isRelated(...clientIds: number[]): Observable<boolean> {
    return this.currentUser$.pipe(
      filter(x => x !== undefined),
      map(x => x && clientIds.includes(x.clientId)),
      first()
    ) as Observable<boolean>;
  }

  isOwner(clientId: number): Observable<boolean> {
    let result = this.currentUser$.pipe(
      filter(x => x !== undefined),
      map(x => x?.clientId == clientId),
      first()
    ) as Observable<boolean>;
    result.subscribe(x => {
      console.log(x)
    })

    return result
  }

  isCreator(userId: number): Observable<boolean> {
    return this.currentUser$.pipe(
      filter(x => x !== undefined),
      map(x => x?.id === userId),
      first()
    ) as Observable<boolean>;
  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  signIn(username: string, password: string): Observable<AppUser> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.signIn({username, password, correlationId}));

    return this.store.select(Selectors.findByCorrelationId<AppUser>(correlationId))
      .pipe(filter((x) => !!x));
  }

  signInToken(token: string, userId: number):  Observable<AppUser> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.signInToken({token, userId, correlationId}));

    return this.store.select(Selectors.findByCorrelationId<AppUser>(correlationId))
      .pipe(filter((x) => !!x));
  }

  signOut() {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.signOut({correlationId}));
  }

  hasPermission(permission: Permissions | Permissions[]): boolean {
    const userClaims = (<Permissions[] | null>JSON.parse(localStorage.getItem('claims') ?? 'null'));

    if (userClaims == null) {
      return false;
    }

    if (Array.isArray(permission)) {
      const permissionArr = (<Permissions[]>permission);
      return userClaims.some((x) => permissionArr.includes(x)) ?? false;
    }

    return userClaims.includes(permission) ?? false;
  }

  getUserFullName(userId: number | undefined): Observable<string | undefined> {
    if (!userId) {
      return of(undefined);
    }
    return this.all$.pipe(filter((x) => x.length > 0), map((x) => {
      const nameParts = x.find((y) => y.id === userId)?.fullName.split(' ');
      if (!nameParts) {
        return '';
      }
      return `${nameParts[0].charAt(0)}. ${nameParts[1]}`;
    }), first());
  }

  // loadLimited(showFeedback = true): string {
  //     const correlationId = uuidv4();
  //     this.store.dispatch(Actions.loadLimited({ showFeedback, correlationId }));
  //     return correlationId;
  // }

  setCurrent(entity: Entity): void {
    this.store.dispatch(Actions.setCurrentEntity({entity}));
  }

  setCurrentById(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityById({entityId}));
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.load({criteria}));
  }

  loadWithResult(criteria: Criteria = undefined): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({criteria, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadSingleWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadSingleIfNecessary({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x), first());
  }

  create(entity: Entity): void {
    this.store.dispatch(Actions.create({entity}));
  }

  createWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.create({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createMany(entities: Entity[]): void {
    this.store.dispatch(Actions.createMany({entities}));
  }

  createManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }


  updateMany(entities: Entity[]): void {
    this.store.dispatch(Actions.updateMany({entities}));
  }

  updateManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.updateMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }
}
