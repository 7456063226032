import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {AppRole as Entity} from "./app-role.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";




export interface AppRoleState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntity: Entity | undefined,
  history: { correlationId: string, payload: Entity | Entity[] | number | IResultWithPageInfo<Entity> }[];
}

export const appRoleEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: AppRoleState = appRoleEntityAdapter.getInitialState({
  entities: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
  currentSort: undefined,
  currentEntity: undefined,
  history: []
});
