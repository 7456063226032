import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {TemplateGraphic} from "./template-graphic.model";
import {ProductCategory} from "../product-category/product-category.model";

@Injectable({providedIn: 'root'})
export class TemplateGraphicService {
    constructor(private http: HttpClient) {
    }

    clone(id: number, cloneProducts: boolean): Observable<TemplateGraphic> {
      return this.http.get<TemplateGraphic>(
          `${environment.rootUrl}/template/clone/${id}/${cloneProducts}`
      );
    }

  updateWithResources(entities: TemplateGraphic[]): Observable<TemplateGraphic[]> {
    console.log(entities)
    return this.http.post<TemplateGraphic[]>(
      `${environment.rootUrl}/template-graphic/update-resources/`,
      entities
    );
  }
}
