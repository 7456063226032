import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Checkout, Checkout as Entity, OrderResult} from './checkout.model';
import {CheckoutActions as Actions} from './checkout.actions';
import {CheckoutSelectors as Selectors} from "./checkout.selectors";
import {AppState} from "src/app/store/app-store.state";
import {generateUniqueId} from "src/app/store/app-store.functions";
import {PaginatedResult} from "src/app/store/app-store-entity.service";
import {filter, first, map} from "rxjs/operators";
import {CheckoutRowFacade} from "src/app/store/checkout-row/checkout-row.facade";
import {WebOrderStatus} from "src/app/store/checkout/checkout.enums";
import {CheckoutRow} from "src/app/store/checkout-row/checkout-row.model";
import { Criteria } from "src/app/store/app-store.types";
import {IPage, ITableSort} from "src/app/store/app-store.interfaces";


@Injectable({providedIn: 'root'})
export class CheckoutFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<Error | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  active$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.activeCheckout));
  totalPageable$: Observable<number> = this.store.pipe(select(Selectors.totalPageable));
  currentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));

  private _refreshPage = new Subject<void>();
  refreshPage$: Observable<void> = this._refreshPage.asObservable();

  constructor(private store: Store<AppState>,
              private checkoutRows: CheckoutRowFacade) {

  }

  getActiveCheckout(): void {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.getActiveCheckout({correlationId}));
  }

  setActiveCheckout(entity: Entity): void {
    this.store.dispatch(Actions.setActiveCheckout({entity}));
  }

  setCurrentId(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityId({entityId}));
  }

  deselectCurrent(): void {
    this.store.dispatch(Actions.deselectCurrent());
  }

  deselect(): void {
    this.store.dispatch(Actions.deselectEntities());
  }

  refreshPage(): void {
    this._refreshPage.next();
  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
    this.refreshPage();
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  startCheckoutStatusPolling() {
    this.store.dispatch(Actions.startCheckoutStatusPolling());
  }

  stopCheckoutStatusPolling() {
    this.store.dispatch(Actions.stopCheckoutStatusPolling());
  }

  sendProductionFiles(checkoutRowId: number): Observable<Checkout> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.sendProductionFiles({checkoutRowId, correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  exportProductionFiles(checkoutId: number): Observable<Checkout> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.exportProductionFiles({checkoutId, correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createOrderWithResult(checkout: Checkout) {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.createOrder({checkout: checkout, correlationId}));

    return this.store.select(Selectors.findByCorrelationId<OrderResult>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getWebOrderStatusName(webOrderStatus: number): string {
    switch (webOrderStatus) {
      case WebOrderStatus.Ok:
        return 'Ok';
      case WebOrderStatus.Pending:
        return 'Pending';
      default:
        return 'N/A';
    }
  }

  getCurrentCheckoutRows(): Observable<CheckoutRow[] | undefined> {
    return combineLatest([
      this.active$,
      this.checkoutRows.all$,
    ]).pipe(
      map(([currentCheckout, checkoutRows]) => {
        let result = checkoutRows.filter((y) => y.checkoutId === currentCheckout?.id)
        return result;
      }),
      first(),
    );
  }

  setCurrent(entity: Entity): void {
    this.store.dispatch(Actions.setCurrentEntity({entity}));
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadProductionOrdersPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadProductionOrdersPage({ page: {pageNumber, pageSize}, criteria, correlationId }));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.load({criteria}));
  }

  loadWithResult(criteria: Criteria = undefined): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({criteria, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  loadSingleWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadSingleIfNecessary({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x), first());
  }

  create(entity: Entity): void {
    this.store.dispatch(Actions.create({entity}));
  }

  createWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.create({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createMany(entities: Entity[]): void {
    this.store.dispatch(Actions.createMany({entities}));
  }

  createManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }

  removeWithResult(entityId: number): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.remove({entityId, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }


  updateMany(entities: Entity[]): void {
    this.store.dispatch(Actions.updateMany({entities}));
  }

  updateManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.updateMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }
}
