import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {LoginComponent} from "src/app/features/login/login.component";
import {AuthRoutingModule} from "src/app/auth/auth-routing.module";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    LoginComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        MatButtonModule,

        MatInputModule,
        AsyncPipe,
        MatProgressBarModule,
    ],
  exports: [LoginComponent]
})
export class LoginModule {
}
