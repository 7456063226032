import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserFacade} from "src/app/store/app-user/app-user.facade";
import {firstValueFrom} from "rxjs";
import {environment} from "src/environments/environment";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    private readonly loginToken: string | null = this.route.snapshot.queryParamMap.get('token');
    private readonly userId: string | null = this.route.snapshot.queryParamMap.get('userId');

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        public appUsers: AppUserFacade,
    ) {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(5), Validators.pattern("")]],
        });
    }

    async ngOnInit(): Promise<void> {
        if (!this.loginToken || !this.userId || isNaN(Number(this.userId))) {
            return;
        }

        await firstValueFrom(this.appUsers.signInToken(this.loginToken, Number(this.userId)));
        await this.router.navigate(['/home']);
    }

    async signIn() {
        if (!this.loginForm.valid) {
            return;
        }

        await firstValueFrom(this.appUsers.signIn(
            this.loginForm.get('email')?.value,
            this.loginForm.get('password')?.value
        ));

        await this.router.navigate(['/home']);
    }
}
