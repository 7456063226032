import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ResourceItem as Entity} from "./resource-item.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface ResourceItemState extends EntityState<Entity> {
  entities: { [id: string]: Entity };
  ids: string[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentPageEntities: number[] | undefined,
  currentSort: ITableSort | undefined;
  currentEntityId: string | undefined,
  history: { correlationId: string, payload: Entity | Entity[] | string | string[] | IResultWithPageInfo<Entity> }[];
}

export const resourceItemEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: ResourceItemState = resourceItemEntityAdapter.getInitialState({
  entities: undefined,
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 25 }, totalCount: 0},
  currentPageEntities: undefined,
  currentSort: undefined,
  currentEntityId: undefined,
  history: []
});
