import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppRole as Entity} from './app-role.model';
import {AppRoleActions as Actions} from './app-role.actions';
import {AppRoleSelectors as Selectors} from "./app-role.selectors";
import {AppState} from "src/app/store/app-store.state";
import {generateUniqueId} from "src/app/store/app-store.functions";
import {PaginatedResult} from "src/app/store/app-store-entity.service";
import {filter} from "rxjs/operators";
import { Criteria } from "src/app/store/app-store.types";
import {IPage, ITableSort, IResultWithPageInfo, IPageInfo} from "src/app/store/app-store.interfaces";


@Injectable({providedIn: 'root'})
export class AppRoleFacade {
  all$: Observable<Entity[]> = this.store.pipe(select(Selectors.selectAll));
  isLoading$: Observable<boolean> = this.store.pipe(select(Selectors.isLoading));
  hasError$: Observable<Error | undefined> = this.store.pipe(select(Selectors.hasErrors));
  current$: Observable<Entity | undefined> = this.store.pipe(select(Selectors.currentEntity));
  totalPageable$: Observable<number> = this.store.pipe(select(Selectors.totalPageable));
  currentPage$: Observable<IPage> = this.store.pipe(select(Selectors.currentPage));
  currentSort$: Observable<ITableSort | undefined> = this.store.pipe(select(Selectors.currentSort));

  constructor(private store: Store<AppState>) {

  }

  setPaginationSettings(page: IPage): void {
    this.store.dispatch(Actions.setPaginationSettings({page}));
  }

  setSortSettings(tableSort: ITableSort): void {
    this.store.dispatch(Actions.setSortSettings({tableSort}));
  }

  setCurrent(entity: Entity): void {
    this.store.dispatch(Actions.setCurrentEntity({entity}));
  }

  setCurrentById(entityId: number): void {
    this.store.dispatch(Actions.setCurrentEntityById({entityId}));
  }

  loadPage(pageNumber: number, pageSize: number, criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.loadPage({criteria, page: {pageNumber, pageSize}}));
  }

  loadPageWithResult(pageNumber: number, pageSize: number, criteria: Criteria = undefined): Observable<PaginatedResult<Entity>> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.loadPage({page: {pageNumber, pageSize}, criteria, correlationId: correlationId}));
    return this.store.select(Selectors.findByCorrelationId<PaginatedResult<Entity>>(correlationId))
      .pipe(filter((x) => !!x));
  }

  load(criteria: Criteria = undefined): void {
    this.store.dispatch(Actions.load({criteria}));
  }

  loadWithResult(criteria: Criteria = undefined): Observable<Entity[]> {
    const correlationId = generateUniqueId();
    this.store.dispatch(Actions.load({criteria, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  create(entity: Entity): void {
    this.store.dispatch(Actions.create({entity}));
  }

  createWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.create({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  createMany(entities: Entity[]): void {
    this.store.dispatch(Actions.createMany({entities}));
  }

  createManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.createMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  update(entity: Entity): void {
    this.store.dispatch(Actions.update({entity, correlationId: generateUniqueId()}));
  }

  updateWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.update({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  remove(entityId: number): void {
    this.store.dispatch(Actions.remove({entityId}));
  }


  updateMany(entities: Entity[]): void {
    this.store.dispatch(Actions.updateMany({entities}));
  }

  updateManyWithResult(entities: Entity[]): Observable<Entity[]> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.updateMany({entities, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity[]>(correlationId))
      .pipe(filter((x) => !!x));
  }

  clone(entity: Entity): void {
    this.store.dispatch(Actions.clone({entity}));
  }

  cloneWithResult(entity: Entity): Observable<Entity> {
    const correlationId = generateUniqueId();

    this.store.dispatch(Actions.clone({entity, correlationId: correlationId}));

    return this.store.select(Selectors.findByCorrelationId<Entity>(correlationId))
      .pipe(filter((x) => !!x));
  }

  getById(entityId: number): Observable<Entity | undefined> {
    return this.store.pipe(select(Selectors.selectById(entityId)));
  }
}
