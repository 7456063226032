import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Product as Entity} from "./product.model";
import {IPageInfo, IResultWithPageInfo, ITableSort} from "../app-store.interfaces";


export interface ProductState extends EntityState<Entity> {
  entities: { [id: number]: Entity };
  ids: number[];
  isLoading: boolean;
  error: Error | undefined;
  currentPage: IPageInfo;
  currentSort: ITableSort | undefined;
  currentEntityIds: number[];
  currentEntityId: number | undefined;
  history: { correlationId: string, payload: Entity | Entity[] | number | number[] | IResultWithPageInfo<Entity> }[];
  totalCount: number;
  currentColours: string[] | undefined;
}

export const productEntityAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>(
  {
    selectId: (product: Entity) => product.id
  }
);

export const initialState: ProductState = productEntityAdapter.getInitialState({
  entities: [],
  ids: [],
  isLoading: false,
  error: undefined,
  currentPage: { page: { pageNumber: 0, pageSize: 50 }, totalCount: 0},
  currentSort: undefined,
  currentEntityId: undefined,
  currentEntityIds: [],
  history: [],
  totalCount: 0,
  currentColours: undefined,
});
