import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {UserResource} from "./user-resource.model";

@Injectable({providedIn: 'root'})
export class UserResourceService {
  constructor(private http: HttpClient) {
  }

  getTotalCount(): Observable<number> {
    return this.http.get<number>(
      `${environment.rootUrl}/user-resource/getall`
    )
  }

  createWithFile(userResource: UserResource, formFile: File): Observable<UserResource> {
    const formData = new FormData()
    formData.append('formFile', formFile);

    for (let key in userResource) {
      formData.append(`UserResource.${key}`, (userResource as any)[key]);
    }

    return this.http.post<UserResource>(`${environment.rootUrl}/user-resource/create-with-file/`, formData);
  }

  clone(id: number, cloneProducts: boolean): Observable<UserResource> {
    return this.http.get<UserResource>(
      `${environment.rootUrl}/user-resource/clone/${id}/${cloneProducts}`
    );
  }
}
