import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ProductPlacement} from "./product-placement.model";

@Injectable({providedIn: 'root'})
export class ProductPlacementService {
    constructor(private http: HttpClient) {
    }

    createWithFile(productPlacement: ProductPlacement, formFile: File): Observable<ProductPlacement> {
      const formData = new FormData()
      formData.append('name', productPlacement.name);
      formData.append('productId', productPlacement.productId.toString());
      formData.append('formFile', formFile);

      return this.http.post<ProductPlacement>(`${environment.rootUrl}/product-placement/create-with-file/`, formData);
    }

    clone(id: number, cloneProducts: boolean): Observable<ProductPlacement> {
        return this.http.get<ProductPlacement>(
            `${environment.rootUrl}/product-placement/clone/${id}/${cloneProducts}`
        );
    }
}
