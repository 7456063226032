import {createSelector} from '@ngrx/store';
import {productEntityAdapter as EntityAdapter, ProductBaseState as EntityState} from "./product-base.state";
import {ProductBase as Entity} from './product-base.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.productBase;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);
export const isLoadingEditor$ = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);
export const currentEntityIds = createSelector(selectState, (state: EntityState) => state.currentEntityIds);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const currentEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityIds
    ? state.currentEntityIds.map(id => state.entities[id]).filter(y => y !== undefined)
    : undefined;
});

export const isCurrentEntity = (entityId: number) =>
  createSelector(selectState, (state: EntityState) => state.currentEntityId === entityId);

export const currentPageEntities = createSelector(selectState, (state: EntityState) => {
  return state.currentPageEntities
    ? state.currentPageEntities.map(id => state.entities[id]).filter(y => y !== undefined)
    : undefined;
});

export const currentEditorPageEntities  = createSelector(selectState, (state: EntityState) => {
    return state.selectForEditorEntities
        ? state.selectForEditorEntities.filter(y => y !== undefined)
        : undefined;
});

export const currentEditorEntitiesCurrentPage = createSelector(selectState, (state: EntityState) => state.selectForEditorEntitiesCurrentPage.page);
export const currentEditorEntitiesCurrentSort = createSelector(selectState, (state: EntityState) => state.selectForEditorEntitiesCurrentSort);
export const currentEditorEntitiesTotalPageable = createSelector(selectState, (state: EntityState) => state.selectForEditorEntitiesCurrentPage.totalCount);

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);
export const totalCount = createSelector(selectState, (state: EntityState) => state.totalCount);
export const ProductBaseSelectors = {
  selectAll,
  selectById,
  isLoading,
  isLoadingEditor$,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  currentEntityId,
  totalPageable,
  currentPage,
  currentSort,
  totalCount,
  currentEntityIds,
  currentEntities,
  isCurrentEntity,
  currentPageEntities,
  currentEditorPageEntities,
  currentEditorEntitiesCurrentPage,
  currentEditorEntitiesCurrentSort,
  currentEditorEntitiesTotalPageable
};
