import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ResourceItem} from "./resource-item.model";

@Injectable({providedIn: 'root'})
export class ResourceItemService {
    constructor(private http: HttpClient) {
    }

  makeTransparent(resourceItem: ResourceItem): Observable<ResourceItem> {
    return this.http.post<ResourceItem>(
      `${environment.rootUrl}/resource-item/make-transparent`, resourceItem
    );
  }

    clone(id: number | string): Observable<ResourceItem> {
        return this.http.get<ResourceItem>(
            `${environment.rootUrl}/resource-item/clone/${id}`
        );
    }
}
