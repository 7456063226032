import {createSelector} from '@ngrx/store';
import {productPlacementEntityAdapter as EntityAdapter, ProductPlacementState as EntityState} from "./product-placement.state";
import {ProductPlacement as Entity} from './product-placement.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.productPlacement;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
  createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const selectTemporaryById = (tempId: number) =>
  createSelector(selectStoreChanges, (entities: Entity[]) => entities.find(p => p.tempId === tempId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const selectStoreChanges = createSelector(selectState, (state: EntityState) => {
  return state.entitiesTemporary
});

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
  selectState,
  (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntityId = createSelector(selectState, (state: EntityState) => state.currentEntityId);

export const currentEntity = createSelector(selectState, (state: EntityState) => {
  return state.currentEntityId
    ? state.entities[state.currentEntityId]
    : undefined
});

export const totalPageable = createSelector(selectState, (state: EntityState) => state.currentPage.totalCount);
export const currentPage = createSelector(selectState, (state: EntityState) => state.currentPage.page);
export const currentSort = createSelector(selectState, (state: EntityState) => state.currentSort);
export const ProductPlacementSelectors = {
  selectAll,
  selectById,
  isLoading,
  hasErrors,
  findByCorrelationId,
  currentEntity,
  currentEntityId,
  totalPageable,
  currentPage,
  currentSort,
  selectStoreChanges,
  selectTemporaryById
};
