import {createSelector} from '@ngrx/store';
import {checkoutRowEntityAdapter as EntityAdapter, CheckoutRowState as EntityState} from "./checkout-row.state";
import {CheckoutRow as Entity} from './checkout-row.model';
import {AppState} from "src/app/store/app-store.state";

export const selectState = (state: AppState) => state.checkoutRow;

export const selectAll = createSelector(selectState, EntityAdapter.getSelectors().selectAll);

export const selectById = (entityId: number) =>
    createSelector(selectAll, (entities: Entity[]) => entities.find(p => p.id === entityId));

export const isLoading = createSelector(selectState, (state: EntityState) => state.isLoading);

export const hasErrors = createSelector(selectState, (state: EntityState) => state.error);

export const findByCorrelationId = <T>(correlationId: string) => createSelector(
    selectState,
    (state: EntityState) => <T>state.history.find(h => h.correlationId === correlationId)?.payload
);

export const currentEntity = createSelector(selectState, (state: EntityState) => state.currentEntity);

export const CheckoutRowSelectors = {
    selectAll,
    selectById,
    isLoading,
    hasErrors,
    findByCorrelationId,
    currentEntity
};
